import React from "react";
import Typography from "@material-ui/core/Typography";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import Filter from "../images/icons/filter";

const res = {
  "newStory" : "New Story",
  "newStoryTooltipHeader" : "Create a new story",
  "newStoryTooltipBody": "Creates the framework for your story, timeline and events.",
  "newEventTooltipHeader": "Create a new event",
  "newEvent" : "New event",
  "newEventTooltipBody1": "Create a new event in the timeline",
  "newEventTooltipBody2": "Create the event via the context menu ",
  "newEventTooltipBody3": "(left click on free position)",
  "newEventTooltipBody4": " in the timeline, if you want to preset the time directly.",

  "search": "Search",
  "searchEventsAndStories" : "Search events and stories",
  "searchEventsAndStoriesTooltip1": "- Search for events in the timeline while typing",
  "searchEventsAndStoriesTooltip2": "- Search for stories",

  "filter" : "Filter",
  "filterEvents" : "Filter out events",
  "filterEventsTooltip1": "Show and hide events according to different criteria",

  "measure": "Measure",
  "measureTooltip": "Measuring times in the timeline",

  "help": "Help",

  "more": "more...",

  "loginInfoNotLoggedIn": "You are not logged in",
  "loginInfoDoLoginHere": "Log in here.",
  "login": "Log in",
  "loginWithGoogle": "Log in with Google",
  "loginWithFacebook": "Log in with Facebook",
  "loginWithVIDIS": "Log in with school-account (VIDIS)",
  "loginWithFobizz": "Log in with Fobizz",
  "loginWithEdulog": "Log in with school-account (edulog)",

  "storiesNearby": "Stories nearby",
  "searchStories": "Search stories",
  "myStories": "My stories",
  "recentlySeen": "Recently seen",
  "usedImagesAndSources": "The images used and their sources.",
  "sourcesOnlyGiven": "Sources are only given if the author has deposited them.",
  "imageSourceReferences": "Image source references",

  "settings": "Settings",
  "separatorTimelineDesc": "Separator for timeline/description",
  "horizontal": "horizontal",
  "vertical": "vertical",
  "showSidewaysDuringEventSelection": "show sideways during event selection",
  "alwaysSuperimpose": "always superimpose",
  "automatic": "automatically",
  "detailView": "Detail view",
  "eventLabeling": "Event labeling",
  "fullLabeling":"full labeling",
  "maxAsLongAsBar": "maximum as long as event bar",
  "showTimelineBackgroundImage": "Backgroundimage of timeline",
  "showTimelineBackgroundImageTrue": "show",
  "showTimelineBackgroundImageFalse": "hide",
  "timelineTheme": "Timeline theme",
  "dark": "dark",
  "bright": "bright",
  "chooseStory": "Choose story",

  "close": "Close",
  "save": "Save",
  "ok": "OK",
  "cancel": "Cancel",
  "yes": "Yes",
  "no": "No",
  "delete": "Delete",
  "copy": "Copy",
  "edit": "Edit",
  "back": "Back",
  "understoodAndSave": "Understood and save",

  "error": "Error",
  "errorInternal": () => (<>An unknown internal error has occurred.<br/>Please contact a histomania representative.</>),
  "errorMailOrPwdNotFound": (fatLink, showRequestNewPassword) => (<>We do not know your email address or the password you entered.<br/>If you have forgotten your password, please request a new one <span style={fatLink} onClick={showRequestNewPassword}>here</span>.</>),
  "msgPasswordSent": () => (<>We have sent you an email with a confirmation link.<br/>Please click on this link so that we can send you a new password.</>),
  "msgNotFound": () => (<>The requested event was not found.<br/>Please get in touch with a histomania contact.</>),
  "errorNetwork": () => (<>An error has occurred.<br/>Please ensure a working internet connection.</>),
  "msgAccountCreated": () => (<>Your account has been created.<br/>We have sent you your login details by email.<br/>Please also check your spam folder if necessary.</>),
  "errorDuplicateUserEntry": () => (<>Wir konnten dein Konto nicht neu anlegen, da der Benutzer bereits in unserer Datenbank vorhanden war.<br/>Bitte melde dich mit deinen bestehenden Daten an, oder lass dir unter "Passwort vergessen" ein neues Passwort zusenden.</>),
  "errorUserNotFound": () => (<>The user does not exist.</>),
  "errorUnknown": () => (<>An unknown error has occurred.<br/>Please contact a histomania representative.</>),
  "titleNewPasswordRequested": "New password requested",
  "titleAccountCreated": "Account creation",
  "or": "or",
  "createAccount": "Create your new histomania account here for free with just a few details",
  "exportToExcel": "Export data to MS-Excel",
  "exportToJSON": "Export data to JSON",
  "addToWebsite": "Add the currently displayed stories to your website by inserting the code below.",
  "codeMustBeInBodyTag": "The code must be inside the body tag of your HTML document.",
  "embedAcceptAGB": "By embedding content from histomania in your website or app, you accept histomania's General Terms and Conditions of Business and Use.",
  "permissions": "Permissions",
  "profile": "Profile",
  "username": "username",
  "firstName": "first name",
  "lastName": "last name",
  "company": "institution",
  "country": "country",
  "state": "state",
  "idprovider": "id provider",
  "role": "role",
  "inputFirstName": "Please enter your first name",
  "inputSurName": "Please enter your last name",
  "newsLetterInfo": "The newsletter is published about 5-6 times a year and informs you about news and selected stories.",
  "subscribeNewsletter": "Subscribe to newsletter",
  "loading": "loading",
  "changePassword": "Change password",
  "pwdChangeSuccessful": "Your password has been successfully changed.",
  "pwdChangeErr": "Error when changing the password.",
  "pwdLength": "The selected password must contain at least 8 characters.",
  "pwdRepetitionError":"The repeated password is different from the first password entered.",
  "pwdChange":"Change password",
  "pwdOld": "Old password",
  "pwdNew": "New password (min. 8 characters)",
  "pwdNewRepetition": "New password (repetition)",
  "showWikipediaTooltip": "show Wikipedia article",
  "whatIf": "What if...",
  "changeTime": "Change time",
  "moveTimelineFromRefDate": "Move the timeline from one reference date to another:",
  "moveTimelineByDuration": "Or move by a certain duration:",
  "noteTimelineIsNotSaved":"Note: The changed timeline is not saved.",
  "moveMeasuringStick" : "Move the measuring stick to the indicated time",
  "moveMeasuringStickBy": "Move the measuring stick by:",
  "moveMeasuringStickTo": "Move the measuring stick to:",
  "toPast": "into the past",
  "toFuture": "into the future",
  "imageWithLicenseImg" : "***Image:***",
  "dragNdropHint": "Drag-n-drop a file or click to add image",
  "maxFileSize": "Maximum file size:",
  "info": "Info",
  "attention": "Attention",
  "articleOnWikipedia": "Article on Wikipedia",
  "wikidataRecord": "Wikidata record",
  "showLocationOnGoogleMaps": "Show location on Google Maps",
  "showStoriesNearby": "More stories nearby",
  "showStoriesAtThatTime": "What else was going on at that time?",
  "showVideosOnYoutube": "Videos on YouTube",
  "showBooksOnAmazon": "Books on Amazon",
  "showDetails": "Show details",
  "details": "Details",

  "now": "now",
  "closeWithoutSave":"End editing without saving?",
  "closeWithoutSaveQuestion": () => (<>Click 'Yes' and save your changes.<br/>Otherwise all changed data will be lost.</>),
  "seconds": "Seconds",
  "minutes": "Minutes",
  "hours": "Hours",
  "days": "Days",
  "untilNow": "until now",
  "secondsUntilNow": "seconds until now",
  "minutesUntilNow": "minutes until now",
  "hoursUntilNow": "hours until now",
  "daysUntilNow": "days until now",
  "unknownDuration": "unknown duration",
  "oClock": "o' clock",
  "pastOrFutureDuration": (isPast, duration) => ((isPast ? duration + " ago" : "in "+duration)),
  "startType": "Start type",
  "endType": "End type",
  "pointInTime": "Point in time",
  "born": "born",
  "earliest": "earliest",
  "start": "Start",
  "origin": () => (<span><b>Origin</b><br/><Typography variant="caption">or foundation/creation</Typography></span>),
  "discovery": "Discovery",
  "publication": "Publication",
  "firstDescription": "First description",
  "baptized": "baptised",
  "premiere": "Premiere",
  "opened": "opened",
  "activeSince": "active since",
  "died": "died",
  "latest": "at the latest",
  "end": "End",
  "decommissioning": "Decommissioning",
  "dissolved": "Dissolved",
  "lost": "missing since",
  "activeUntil": "active until",
  "discontinued": "discontinued",
  "closed": "closed",
  "precision": "precision",
  "1000000000years": "1 billion years",
  "100000000years": "100 million years",
  "10000000years": "10 million years",
  "1000000years": "1 million years",
  "100000years": "100 thousand years",
  "10000years": "10 thousand years",
  "millenium": "Millennium",
  "century": "Century",
  "decade": "Decade",
  "year": "Year",
  "month": "Month",
  "day": "Day",
  "hour": "Hour",
  "minute": "Minute",
  "exact": "Exact",
  "duration": "Duration",
  "years": "years",
  "mon.": "mo.",
  "h.": "hrs.",
  "min.": "min.",
  "noTimeChosen": "No date selected",
  "today": "today",
  "weekdays": ()=>(["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]),
  "internalServerError": ()=>(<div>An internal server error has occurred.<br/>Please get in touch with a histomania contact.</div>),
  "userNotAcknowledged": ()=>(<div>The user exists in our system but has not yet been confirmed by you via the link in the email.</div>),
  "userOrPwdNotInSystem": ()=>(<div>We do not know the user or the password entered.</div>),
  "oldPasswordUnknown": ()=>(<div>We do not know the old password.</div>),
  "pwdGuideline": ()=>(<div>The data entered does not comply with the guidelines.</div>),
  "noDataRefresh": ()=>(<div>Data could not be updated.</div>),
  "emailAlreadyAvailable": ()=>(<div>The entered e-mail address already exists</div>),
  "userNotCreated": ()=>(<div>The user could not be created</div>),
  "reCaptchaError": ()=>(<div>Google reCaptcha: You could not be identified as a human.</div>),
  "notPlausible": ()=> (<div>The inputs are not plausible.</div>),
  "sessionInvalid": ()=>(<div>The session is no longer valid.<br/>Please log in again.</div>),
  "unknownError": (errorCode)=>(<div>An unknown error with the number {errorCode} has occurred.<br/>Please contact a histomania representative.</div>),
  "hideStory": ()=>(<><Typography color="inherit">Hide history</Typography><hr/>You can quickly find this story again after hiding it by searching <br/><em>'recently seen'.</em>.</>),
  "showStory": ()=>(<><Typography color="inherit">Show story</Typography><hr/>Open the story in a new tab and add the timeline in parallel to existing timelines.</>),
  "showStory2": "hiStory anzeigen",
  "restrictYourSearch": (totalcnt)=>("Too many results (" + totalcnt + "). Please narrow the search query further."),
  "allowGeoLocation": "Please allow histomania to access your location in the settings first.",
  "everytime": "everytime",
  "everywhere": "everywhere",
  "myNeighbourhood": "in my neighbourhood",
  "inNeighbourhood": "in the neighbourhood of",
  "search2": "Search",
  "currentView": ()=>(<>current<br/>view</>),
  "recentlySeen2": ()=>(<>recently<br/>seen</>),
  "myStories2": ()=>(<>my<br/>stories</>),
  "searchPhrase": "Search terms",
  "inputSearchPhrase": "Enter search term",
  "searchPhraseSample": "e.g.: Physicist Albert",
  "minimumCharacters": (minSearchWordCharCnt)=>("at least " + minSearchWordCharCnt + " characters per search term"),
  "when": "event period",
  "where": "place",
  "inNeighbourhoodOf": "in the neighbourhood of ...",
  "chosenPosition": "selected position",
  "latitude": "latitude",
  "longitude": "longitude",
  "searchTimelines": "Search stories",
  "searchStoriesInNeighbourhood": "Looking for stories nearby",
  "resultsFromLocalSearch": "Events from the local search (click for details):",
  "recommended": "Recommended hiStories",
  "todaysBirthdays": "Birthdays today",
  "todaysDeathdays": "Deathdays today",
  "anniversaries": "Anniversaries of events today",
  "hideAll": "Hide all",
  "inCurrentView": "in current view",
  "privateTimeline": "private hiStory",
  "less": "less...",
  "image": "Image",

  "logoURL": "https://"+window.location.host,
  "wikiLicenseCCSA": (site) => (<>Source: en.wikipedia.org - The text is available under the <a target="_blank" rel="noopener noreferrer" href={"https://en.wikipedia.org/wiki/Wikipedia:Text_of_Creative_Commons_Attribution-ShareAlike_3.0_Unported_License"}>"Creative Commons Attribution/Share Alike"</a> licence.<br/>Here you can find the list of <a target="_blank" rel="noopener noreferrer" href={"https://xtools.wmflabs.org/articleinfo-authorship/en.wikipedia.org/"+site+"?uselang=en"}>authors</a>.</>),
  "wikiURL": "https://en.wikipedia.org/wiki/",

  "infiniteCalendarHeaderFormat": "dddd, D MMM",

  "resetfilter": "Reset filter",
  "maintainEventList": "Maintain events in table",

  "importcsv": "Import CSV",
  "import": "Import",

  "prenameError": "Please enter your first name without numbers and special characters. A first name must be at least two characters long.",
  "surnameError": "Please enter your last name without numbers and special characters. A last name must be at least two characters long.",
  "mailError": "Please enter a valid email address so that we can send you a confirmation link.",

  "fonts": "fonts...",
  "bold": "bold",
  "italic": "italic",
  "underline": "underline",
  "code": "code",
  "unorderedList": "unordered list",
  "orderedList": "ordered list",
  "quote": "quote",
  "codeblock": "codeblock",
  "divider": "horizontal divider",
  "alignment": "text alignment...",
  "addImage": "add image",
  "addVideo": "add video",
  "addMap": "add map",
  "addPanorama": "add 360°-view",
  "addQuiz": "add quiz",
  "addInfoblock": "add info-block",
  "addLink": "add link",
  "addEvent": "add event",
  "addStory": "add story",
  "addFilter": "add filter",

  "landingpage_title": "The platform for history on the timeline",
  
  "welcome.js735054413": "Discover the world of history in hiStories, the interactive and multimedia compilation of timelines, texts, images, videos, quizzes, 360° views and maps.", 
  "welcome.js142706916": "Create your own timelines for your resume, a local chronicle or a learning aid for an unforgettable experience.", 
  "welcome.js737110502": "my surroundings", 
  "welcome.js455445377": "ransack",
  "welcome.js695746560": "Discover the world of history in hiStories, the interactive and multimedia compilation of timelines, texts, images, and quizzes.",
  "explanation" : () => (<>
    Experience history in a new way with histomania: An interactive platform that makes it easy for you to tell and understand stories.
    <br/> <br/>
    Never lose sight of time and place as you navigate through thousands of stories and biographies.
    <br/>
    Unlike classic hypertext narratives, where you can only jump from one article to the next, the texts in histomania contain additional links that allow you to follow events on a timeline or map.
    <br/> <br/>
    <PostAddIcon/>  opens a new hiStory
    <br/>
    <ScheduleIcon/>  shows you the event on the timeline
    <br/>
    <RoomIcon/>
    shows you the position of the event on the map
    <br/>
    <Filter/>  filters events in the timeline and chronology
    <br/>
    <br/>
    Explore the orders of magnitude of time periods by comparing timelines of different hiStories and measuring the distances between events within these timelines.
    <br/>
    By comparing and measuring, you will be able to unlock the time periods and epochs of history in a new way.
    <br/>
    <br/>
    Use interactive maps that can contain not only pins for position markers but also arrow chains for travel routes and overlays for displaying areas.
    <br/>
    These can even be animated and have a transparency level chosen by you, giving you a unique understanding of border changes.
    <br/>
    <br/>
    Discover stories in your current surroundings or when traveling in a foreign city at the touch of a button. Find the right stories in museums via a QR code.
    <br/>
    <br/>
    You can even embed your favorite hiStories into your own homepage.
    <br/>
    <br/>
    Whether you want to publish your own hiStories or just surf through history, histomania is the ideal companion for students in history class, students of history, historians, local chroniclers, and anyone who simply enjoys history or current events in politics and world affairs.
  </>),
  "topfacts_wiki": () => (<span>Is there a corresponding article on Wikipedia, or videos on YouTube, for a story, or event in history?<br/>Or if an event is localized, would you like to view it on a Google Maps map, and have it navigate you there right away if necessary?<br/>One click will take you from the details in histomania to the corresponding page.</span>),
  "topfacts.js415020171" : "surf",
  "topfacts.js111683501" : "compare",
  "topfacts.js56475656" : "integrate",
  "topfacts.js939157228" : "free",
  "topfacts.js617723463" : "measure",
  "topfacts.js692398414" : "create",
  "topfacts.js899063949" : "fictional questions",
  "topfacts.js152860411" : "share",
  "topfacts.js55830277" : "linked",
  "topfacts.js335278155" : "search",
  "topfacts.js150418448" : "devicesindependent",
  "topfacts.js820645511" : "from the big bang to the end of the world",
  "topfacts.js645936163" : "By linking historical events, you can navigate from one story to another.",
  "topfacts.js751322491" : "histomania allows you to place several time beams among each other, and thus make them comparable.",
  "topfacts.js921529656" : "Want to embed stories into your own website or blog? Learn more here.",
  "topfacts.js895080977" : "histomania is free in the basic version. No matter if you use it for private, commercial or educational purposes.",
  "topfacts.js948823105" : "How many years older is Marilyn Monroe than James Dean? How old was Einstein when he received the Nobel Prize? Simply measure the times that interest you on the timeline using the integrated caliper.",
  "topfacts.js107256625" : "Write your own story. Depict historical events in a way that shows what is important to you. Or describe your life on a timeline.",
  "topfacts.js102762366" : "What if... you had been born at the same time as Goethe, for example? Create your curriculum vitae, and move it so that you can compare it with that of others.",
  "topfacts.js373990748" : "Work with others on a timeline and use permissions to determine who can see or edit your story.",
  "topfacts.js809530473" : "In addition to searching keywords, you can also search for what happened in specific places during specific time periods. Are you in a strange place and want to learn more about the history of the area? Click on 'What's nearby' and search for events in the vicinity using a GPS-enabled smartphone.",
  "topfacts.js15371141" : "Whether on a smartphone, a tablet or a PC. With histomania you have an application that adapts to all devices, be it to the screen size, or to the way of input via mouse or touch gestures.",
  "topfacts.js678201177" : "histomania can process incredibly large periods of time with adjustable, up to minute precision. Thus, the epochs of the earth's history as well as the history of the first moon landing can be displayed in a meaningful way.",
  "contact": "contact",
  "phone": "phone",
  "newHiStoryMessage" : ()=> (<span>Your new hiStory is now created<br/>Now add events.</span>),
  "histomaniamain.js218992075" : "Please specify at least one administrator.",
  "menu.js844059924" : "New hiStory",
  "storydetailsmain.js785302558" : "new hiStory",
  "storydetailsmain.js382589702": "hiStory",
  "mainFab.js309680386" : "Edit 'hiStory",
  "mainFab.js593675655" : "'Maintain events in table'",
  "mainFab.js968262525" : "'Add event",
  "mainFab.js943257132" : "'new hiStory",
  "bookmarks.js93045028" : "Bookmark",
  "menu.js505082777" : "New event",
  "menu.js756551371" : "What if...?",
  "menu.js663038438" : "What if... Undo",
  "menu.js439620799" : "Bookmark",
  "menu.js157014000" : "Printing and image export",
  "menu.js555525792" : "Import",
  "menu.js923812869" : "Profile management",
  "menu.js450602741" : "My profile",
  "menu.js984392851" : "Change password",
  "menu.js498764961" : "Language",
  "menu.js869701443" : "German",
  "menu.js503793771" : "English",
  "menu.js161223496" : "Ukrainian",
  "menu.js961199351" : "My settings",
  "menu.js165576261" : "Log out",
  "menu.js972296370" : "Log in",
  "bargroup.js313011152" : "Designation",
  "bargroup.js999078620" : "new designation",
  "barshape.js677799618" : "bold bar",
  "barshape.js210728036" : "narrow bar",
  "barshape.js708427194" : "curly bracket",
  "barshape.js853386386" : "transparent background",
  "barshape.js709767519" : "small star",
  "barshape.js620435244" : "Star",
  "barshape.js170641551" : "small circle",
  "barshape.js649456610" : "Circle",
  "barshape.js494726977" : "Cloud",
  "barshape.js735933567" : "Speech bubble",
  "barshape.js931763133" : "Text centered",
  "barshape.js931763134" : "Only baseline",
  "barshape.js509994468" : "small document",
  "barshape.js848987645" : "Document",
  "barshape.js786118528" : "small sun",
  "barshape.js560723628" : "Sun",
  "barshape.js624588809" : "small cross",
  "barshape.js33818872" : "Cross",
  "barshape.js597772577" : "small arrow left",
  "barshape.js170062957" : "Arrow left",
  "barshape.js731660847" : "small arrow right",
  "barshape.js233359132" : "Arrow right",
  "dataset.js729839193" : "Error in the description",
  "dataset.js345733057" : "Designation",
  "dataset.js850812704" : "Unit",
  "descriptionBlock.js240405443" : "Description",
  "descriptionBlock.js884402762" : "Here you can also insert pictures and videos.",
  "designBlock.js426292766" : "Design",
  "designBlock.js902377753" : "This is how the event appears in the timeline.",
  "imageBlock.js428756050" : "Image",
  "imageBlock.js604367201" : "This image appears in the timeline. You can add more images in the description.",
  "imageBlock.js399000806" : "Image license",
  "imagechooser.js38403923" : "Image license",
  "innerevents.js238036015" : "Inner times",
  "sourceReferenceBlock.js99152413" : "References",
  "sourceReferenceBlock.js484508229" : "List here the source(s) proving the event.",
  "timeBlock.js186865327" : "Time",
  "timeBlock.js929675741" : "Put a minus in front of the year for pre-Christian times, e.g. 21.04.-753",
  "timeBlock.js910792715" : "Time",
  "timeBlock.js134397445" : "Period",
  "titleBlock.js239194644" : "Title",
  "titleBlock.js47802846" : "You can use multiple lines.",
  "wikipediareference.js851003152" : "German Wikipedia page (or prefix with country code in square brackets, e.g. [en])",
  "bookmarkitem.js608408822" : "Delete",
  "story.js915248213" : "My hiStory",
  "drafteditortoolbar.js858681026" : "Font",
  "drafteditortoolbar.js823346092" : "Paragraph",
  "drafteditortoolbar.js97337207" : "Media",
  "drafteditortoolbar.js82711167" : "Links",
  "chooseImageDialog.js542504966" : "Title",
  "chooseMapDialog.js901660970" : "Name of the card",
  "chooseMapDialog.js589922616" : "Designation",
  "chooseMapDialog.js87695793" : "Google Maps URL",
  "chooseMapDialog.js10384243" : "Image description and license",
  "chooseStreetViewDialog.js854616579" : "Title",
  "chooseStreetViewDialog.js119725327" : "Streetview Einbettungscode",
  "chooseVideoDialog.js778434054" : "Title",
  "chooseVideoDialog.js671606307" : "YouTube URL or ID",
  "chooseVideoDialog.js799772333" : "Start at second",
  "AddVideoButton.js665672521" : "Create a timeline for free",
  "maintenanceEventList.js998639033" : "Events",
  "filtertimeline.js710501750" : "Filter events",
  "searchfield.js825023426" : "search hiStories for this term",
  "searchfield.js18900516" : "hiStories in my area",
  "searchfield.js363834300" : "Search hiStories by time and place",
  "searchfield.js644144191" : "My hiStories",
  "searchfield.js660560315" : "Recently seen hiStories",
  "searchfield.js961730789" : "Filter current timeline with this term",
  "searchfield.js867502466" : "Filter current timeline",
  "searchfield.js829902906" : "Search suggestions",
  "histomaniamain.js781485964" : "My event",
  "histomaniamain.js702299662" : "Please open or create a hiStory first to create the event.",
  "histomaniamain.js197615179" : "Be careful when sharing your URL.\nYour hiStory is private and cannot be seen by all recipients.",
  "histomaniamain.js12025145" : "Please provide a title with at least three letters for your hiStory.",
  "histomaniamain.js310062970" : "To save the hiStory for a longer time you have to log in.",
  "histomaniamain.js5248846" : "Please first open a hiStory that you are allowed to edit to copy the event there.",
  "histomaniamain.js513585760" : "jump to",
  "import.js261569334" : "Please open a hiStory first to file the events there.",
  "import.js67411235" : "Select the hiStory where the events should be stored.",
  "login.js758426829" : "Log in",
  "login.js779088926" : "E-mail",
  "login.js163307733" : "Password",
  "login.js293038552" : "Stay logged in",
  "login.js116720706" : "No account yet?",
  "login.js319559879" : "First name",
  "login.js278946069" : "Please enter a first name",
  "login.js897560837" : "Last name",
  "login.js237687098" : "Please enter a last name",
  "login.js457756656" : "E-mail",
  "login.js692708124" : "Please enter a valid email address",
  "login.js368777066" : "Password forgotten?",
  "login.js327950210" : "E-mail",
  "login.js493155713" : "Registration",
  "editMediaDialog.js254758074" : "Media list",
  "editMediaDialog.js106456795" : "JSON",
  "markdowneditfield.js110445871" : "Edit panoramic view",
  "markdowneditfield.js117272661" : "Edit quiz",
  "markdowneditfield.js848013488" : "Edit media item",
  "lazygooglemap.js947359452" : "I agree that content from Google Maps is displayed to me. This may transmit personal data to third parties.",
  "QuizEditorTitlePage.js823814415" : "Title",
  "menu.js893403567" : "Share",
  "menu.js932778276" : "as e-mail",
  "menu.js556545983" : "Export data",
  "menu.js413767225" : "Import data",
  "menu.js990897862" : "Help",
  "menu.js171939370" : "from ",
  "print.js570625276" : "The minimum width is",
  "print.js612051645" : "The maximum width is",
  "print.js574630162" : "The minimum beam height is",
  "print.js760791437" : "The maximum beam height is",
  "print.js758964558" : "The maximum overhang has been exceeded.",
  "print.js375853243" : "The supernatant must not be negative.",
  "print.js15990392" : "There must be at least one horizontal part for printing.",
  "print.js556329669" : "There must be at least one vertical part for printing.",
  "print.js833055774" : "The image must not be cut into more than 100 parts.",
  "printdialog.js85755474" : "Overhang due to labeling (",
  "printdialog.js341020472" : "transparent background",
  "printdialog.js665169354" : "Draw shadow",
  "printdialog.js605134089" : "horizontal",
  "printdialog.js839026585" : "vertical",
  "displaystorydetails.js158045992" : "Sources",
  "addstorydescriptor.js92624992" : "Embedded hiStories",
  "addstorydescriptoreditdialog.js502574362" : "Group",
  "addstorydescriptoreditdialog.js124818429" : "ID of the hiStory",
  "addstorydescriptoreditdialog.js117860561" : "unfiltered event IDs",
  "addstorydescriptoreditdialog.js777122119" : "Position",
  "backgroundimageinput.js816937029" : "Image",
  "backgroundimageinput.js218259721" : "This image serves as the background of the timeline and appears in the search. You can add more images in the description.",
  "backgroundimageinput.js429573179" : "Image license",
  "userrightsdialog.js70424287" : "New authorization",
  "userrightsdialog.js480394996" : "E-mail",
  "userrightsdialog.js216158241" : "Please enter a valid email address",
  "bookingrightsadministration.js589033383" : "Permissions",
  "bookingrightsadministration.js546709873" : "everyone may read the hiStory",
  "descriptioninput.js517543907" : "Description",
  "dummyuserhint.js499234330" : "You are not logged in",
  "headlineinput.js150642075" : "Headline",
  "headlineinput.js593049979" : "Title of the hiStory",
  "headlineinput.js477282705" : "Subtitle",
  "newstoryhint.js799008560" : "First steps",
  "sourcereferenceinput.js783166353" : "References",
  "wikipediainput.js705594377" : "Advanced content",
  "search.js726564732" : "Search",
  "search.js671154469" : "anytime",
  "durationpanel.js274332505" : "unknown duration",
  "searchfield.js807364549" : "Search in current timeline",
  "histomaniamain.js734544182" : "The hiStory is only stored for the duration of your session.",
  "menu.js698756284" : "Any changes made so far on a test basis are reset",
  "userrightsdialog.js937814759" : "Edit authorization",
  "bargroup.js45524065" : "Change name everywhere",
  "sourceReferenceBlock.js304249147" : "Provide source information for the event.",
  "timeBlock.js20893151" : "Inner times...",
  "wikipediareference.js907588328" : "I would like to embed a Wikipedia article.",
  "bookmarkdialog.js412170740" : "Add bookmark",
  "bookmarkdialog.js929413458" : "You are not logged in. Your bookmarks will only be valid for about one day.",
  "bookmarks.js475765892" : "Save open timelines as bookmarks",
  "bookmarks.js306859711" : "Really delete?",
  "cookieconsent.js905029185" : "We use cookies and localStorage entries. You can adjust your settings at any time via the menu in the app. If you don't want to accept all cookies or localStorage entries, or if you want to learn more about them, click on 'Show more'.",
  "cookieconsent.js352836975" : "Show more",
  "cookieconsent.js243259253" : "Close",
  "cookieconsent.js645797755" : "Accept all cookies",
  "schoolcookieconsent.js905029185": () => <>We only use technically necessary cookies and localStorage entries on schule.histomania.com that cannot be rejected.<br/>These include a session cookie and localStorage entries that store the language selection, identifiers for the user so that they do not have to log in repeatedly, and confirmation of this window.<br/></>,
  "drafteditortoolbar.js747935826" : "Font",
  "drafteditortoolbar.js616605922" : "Paragraph",
  "drafteditortoolbar.js894699961" : "Media",
  "drafteditortoolbar.js109669568" : "Links",
  "chooseMapDialog.js417538297" : "Step 2:",
  "chooseMapDialog.js561133791" : "Step 3:",
  "chooseMapDialog.js61491380" : "take over",
  "Link.js291014714" : "Open hiStory",
  "Link.js62298905" : "Filter events in timeline",
  "maintenanceeventlisttable.js380608930" : "Designation",
  "maintenanceeventlisttable.js866297000" : "Start",
  "maintenanceeventlisttable.js573824846" : "End",
  "filtertasklist.js930196063" : "All",
  "filtertasklist.js18265885" : "All events",
  "filtertasklist.js560877919" : "Term",
  "filtertasklist.js132146692" : "Period",
  "filtertasklist.js8240783" : "Features",
  "filtertasklist.js192609356" : "Time events",
  "filtertasklist.js774361819" : "Period events",
  "filtertasklist.js700792519" : "Groups",
  "filtertasklist.js482827431" : "Individual events",
  "extendedmenu.js908817077" : "Printing and image export",
  "extendedmenu.js336969410" : "Bookmark",
  "extendedmenu.js82030918" : "Settings",
  "extendedmenu.js7002811" : "Help",
  "extendedmenu.js598943965" : "Language",
  "extendedmenu.js760560118" : "Home",
  "histomaniamain.js114097156" : "Complete timeline in visible area",
  "import.js191606136" : "CSV import",
  "import.js112638704" : "Copy the content of your CSV file into the text field and click 'Import'.",
  "login.js382006560" : "Subscribe to the histomania newsletter while you're at it.",
  "login.js416865926" : "Terms of use",
  "login.js64439934" : "Privacy policy",
  "login.js572661779" : "I have read and accept the terms of use and privacy policy",
  "login.js230957075" : "Create account",
  "login.js878212959" : "Send new password",
  "editMediaDialog.js808123558" : "Media",
  "markdowneditfield.js554353551" : "Fonts",
  "markdowneditfield.js558691581" : "Heading 1",
  "markdowneditfield.js785963602" : "Heading 2",
  "markdowneditfield.js935699377" : "Heading 3",
  "markdowneditfield.js534085527" : "Heading 4",
  "markdowneditfield.js518723970" : "Heading 5",
  "markdowneditfield.js452157683" : "Heading 6",
  "markdowneditfield.js267080676" : "Italic (selected text)",
  "markdowneditfield.js267174421" : "Bold Italic (selected text)",
  "markdownhelper.js160663836" : "Media item could not be found",
  "markdownhelper.js744897963" : "Filter",
  "markdownhelper.js297030165" : "Show place",
  "markdownhelper.js702490738" : "open external website",
  "quizErrorDialog.js818208140" : "A title is required for the quiz.",
  "quizErrorDialog.js316989684" : "Please enter at least one question.",
  "quizErrorDialog.js217047946" : "Question ",
  "quizErrorDialog.js592184179" : "Question ",
  "quizErrorDialog.js91808612" : "Question ",
  "quizErrorDialog.js770785722" : "Question ",
  "quizErrorDialog.js121149031" : "Question ",
  "lazygooglemap.js821091857" : "Unfortunately, for privacy reasons, this subdomain cannot show you any Google Maps content.",
  "media.js334165907" : "Error in the map description",
  "media.js745432641" : "Error in the map description",
  "QuizEditorTitlePage.js567873608" : "Homepage",
  "QuizEditorTitlePage.js3575109" : "Description",
  "QuizQuestion.js522602069" : "Formulate your question here",
  "QuizQuestion.js277305215" : "Add answers and put a check mark by the correct answers",
  "QuizQuestion.js719954254" : "Here can be added an explanation of the answer",
  "QuizQuestion.js631196400" : "This is how many points there should be for a correct answer",
  "streetview.js903524730" : "Unfortunately, for privacy reasons, this subdomain cannot show you any Google Maps content.",
  "youtube.js541386012" : "Unfortunately, YouTube content cannot be displayed in this subdomain for privacy reasons.",
  "circularmenu.js381995722" : "Search",
  "circularmenu.js524039849" : "Measure time intervals in the timeline",
  "circularmenu.js739065959" : "Custom settings",
  "circularmenu.js357509198" : "Bookmark",
  "circularmenu.js636940232" : "All groups on",
  "circularmenu.js355370525" : "Create a new hiStory",
  "circularmenu.js753068960" : "Create a time event",
  "circularmenu.js653781889" : "Create a time segment event",
  "menu.js826958696" : "Privacy policy",
  "menu.js586813367" : "GTC and terms of use",
  "menu.js614038001" : "Imprint",
  "menu.js439856996" : "Image licenses",
  "menu.js942699906" : "Reset cookie settings",
  "printdialog.js317202399" : "Resolution",
  "printdialog.js176250960" : "Time range",
  "printdialog.js161420731" : "Image settings",
  "printdialog.js399246718" : "Share image",
  "printdialog.js150161449" : "Preview",
  "printdialog.js8964786" : "Print",
  "printdialog.js544885797" : "Image export",
  "ratings.js661212453" : "To be able to rate you have to log in.",
  "displaystorydetails.js121722705" : "Show image licenses",
  "bookingrightsadministration.js131215424" : "These users are allowed to edit the hiStory:",
  "newstoryhint.js223326167" : () => (<>Here you tell your hiStory, which forms the framework for your timeline.<br/>For now, however, it is sufficient if you only give your hiStory a title and then save it.<br/>After that, you can start entering the individual events.</>),
  "bookingrights.js286057285" : "The hiStory may be used by",
  "distributerightsdialog.js87761818" : "Distribute rights,",
  "barexpansion.js878339034" : "Height",
  "barexpansion.js887101724" : "-fold",
  "barexpansion.js728758094" : "Set the displayed height of the event as a multiple of the normal height.",
  "bargroup.js152857522" : "Group",
  "bargroup.js914347576" : "Change designation",
  "barposition.js84416775" : "Position",
  "barposition.js560624624" : "Use the position to determine which events should be displayed at the bottom and which at the top.",
  "barsettings.js434654385" : "Color",
  "barsettings.js408327828" : "Choose the color for the event. The font color is selected automatically.",
  "barshape.js315964835" : "Form",
  "barshape.js230080019" : "Choose the shape of the event bar.",
  "dataset.js205796920" : "Diagram data",
  "dataset.js666145317" : "Describe time-dependent graphs.",
  "dataset.js354018274" : "Paste the CSV content here and press OK:",
  "dataset.js218289977" : "Import",
  "editbookingdetails.js985514551" : "Do you really want to delete the event?",
  "imagechooser.js862610110" : "Image to event in timeline",
  "imagechooser.js871519548" : "Specify an image that will appear in the Timeline.",
  "imagechooser.js950070103" : "You can add more pictures in the description.",
  "wikipediareference.js272862577" : "Wikipedia reference",
  "wikipediareference.js904830442" : "Reference to Wikipedia.",
  "bookmarks.js523603774" : "Do you really want to delete the bookmark '{deleteBookmarkS && deleteBookmarkS.name}'?",
  "chooseMapDialog.js720327007" : "Specify the name.",
  "chooseMapDialog.js760709110" : "Then click in the map to set a new marker, or enter the URL from Google maps.",
  "chooseMapDialog.js388797867" : "Include the image description and license if your image is not royalty free.",
  "chooseMapDialog.js842057153" : "Adjust the size in the map. Add control points (at least three) with [Ctrl + Click].",
  "chooseMapDialog.js982829124" : "Then select ",
  "Link.js776621301" : "Event in timeline",
  "Link.js501324063" : "Open another hiStory.",
  "import.js93314536" : "Import from JSON",
  "import.js254441848" : "Import from text field",
  "login.js15485281" : "Please enter the email address you have on file at histomania:",
  "markdowneditfield.js426937653" : "Bold (selected text)",
  "markdowneditfield.js85888028" : "Insert image",
  "markdowneditfield.js249402737" : "Insert video",
  "markdowneditfield.js421012641" : "Insert map",
  "markdowneditfield.js535534111" : "Insert panoramic view",
  "markdowneditfield.js58232052" : "Insert filter",
  "markdowneditfield.js954635745" : "Insert quiz",
  "markdowneditfield.js827493202" : "List of all media",
  "markdownhelper.js904359583" : "Filter events in chronology and timeline.",
  "markdownhelper.js133555219" : "Find event in timeline",
  "markdownhelper.js499018470" : "An arrow shows you the position of the event in the timeline.",
  "markdownhelper.js743956038" : "The position of the event is displayed on a map.",
  "markdownhelper.js813604085" : "A new browser tab opens with the article.",
  "addstorydescriptor.js556883219" : "Embedded hiStories",
  "addstorydescriptor.js110462036" : "new embedded hiStory",
  "bookingrights.js50879320" : "Sign up to share hiStories with others.",
  "admincomponent.js149123880" : "Close",
  "loginbutton.js370395105" : "log in / register for free",
  "mediaitemlistbutton.js846631503" : "List of all media",
  "registerbutton.js180629076" : "register for free",
  "hintdialog.js158836303" : "Close",
  "barexpansion.js805637512" : "normal",
  "barexpansion.js271938727" : "25-fold",
  "barexpansion.js680468558" : "50-fold",
  "barexpansion.js321153821" : "75-fold",
  "barexpansion.js970143224" : "100-fold",
  "barposition.js477225547" : "at the bottom",
  "barposition.js21166688" : "normal",
  "barposition.js400652493" : "at the top",
  "bookingrightsadministration.js501993017" : "Authorize additional users,",
  "streetview.js459438593" : "I agree that content from Google Maps is displayed to me. This may transmit personal data to third parties.",
  "displaystorydetails.js463453345" : "Events. Click an entry for details or the clock icon to find it in the timeline",
  "dataset.newLine" : "new line",
  "dataset.fromCSV" : "from csv",
  "markdowneditfield.name" : "Name",
  "markdowneditfield.birth" : "Date of birth",
  "addmarkdownbutton.53645" : "This is a markdown headline",
  "timeblock.3457335" : "Specify either the duration or the end, the other value is calculated in each case. Without specification the end goes up to the current time.",
  "innerevents.34353463" : "Currently, only the JSON editor is available for input.",
  "bargroup.54345345" : "Events of the same group are displayed in a labeled block in the timeline.<br",
  "cookieconsent.0": "Before you continue",
  "cookieconsent.1" : "Technically necessary cookies",
  "cookieconsent.2" : () => <>Operational cookies cannot be deactivated, otherwise our site will no longer function.<br/>For example, we use session cookies to store the current selection of your hiStories.<br/>Furthermore, pages from YouTube and Google Maps may be embedded in user-generated content.<br/>Cookies may also be set by the aforementioned manufacturers as a result. <br/>We will ask you before loading this content if you want to do so.<br/>We remember your selection in the browser's local storage.<br/><br/></>,
  "cookieconsent.3" : "Analytics cookies",
  "cookieconsent.4" : "We use Google Analytics to create statistics. No personal data is processed in the process. IP addresses are stored anonymously (the last digits are replaced by an X).",
  "cookieconsent.5" : "You can revoke the Cookie and Local Storage settings at any time by selecting 'Reset Cookie Settings' from the menu.",
  "schoolcookieconsent.js645797755": "OK",
  "enlargeToSelection" : "Enlarge to selection",
  "fixDuration" : "Fix duration",
  "endMeasurement" : "Stop measurement",
  "secondsFromNow" : "seconds from now",
  "minutesFromNow" : "minutes from now",
  "hoursFromNow" : "hours from now",
  "daysFromNow" : "days from now",
  "chronology" : "chronology",
  "languageinput.js593099979" : "Language",

  "fontSettings.js878339034" : "Font",
  "fontSettings.js887101724" : "-fold",
  "fontSettings.js728758094" : "Set the height of the caption as a multiple of the normal height.",
  "fontSettings.quarter" : "1",
  "fontSettings.half" : "1/2",
  "fontSettings.threequarters" : "3/4",
  "fontSettings.full" : "normal",
  "fontSettings.oneandahalf" : "1.5 times",
  "fontSettings.two" : "double",

  "fontSettings.transparency": "Opaque",
  "fontSettings.height": "Height",
  "fontSettings.25percent": "25%",
  "fontSettings.50percent": "50%",
  "fontSettings.75percent": "75%",
  "fontSettings.100percent": "100%",
  "fontSettings.bold": "bold",
  "fontSettings.italic": "italic",

  "deleteProfile": "Delete my profile",
  "deleteProfileContent": () => <>Deleting your profile does not delete your hiStories.<br/>They may remain visible to other users under an anonymised profile.<br/>If you want your hiStories to no longer be accessible to others, delete them before you delete your profile.<br/><br/>Do you want to delete your profile irrevocably?</>,

  "decorationdescriptor.js556883219": "decoration",
  "decorationdescriptor.js92624992": "decoration",
  "menu.mapForTimeInterval": "Summarised map from time interval",
  "relation.js272862577" : "Connection lines",
  "relation.js904830442" : "Connection lines",

  "connection.target" : "target",
  "connection.name" : "name",
  "connection.color" : "color",
  "connection.width" : "width",
  "connection.textpos" : "textposition",
  "connection.startpos" : "startposition",
  "connection.endpos" : "endposition",

  "schoolclass6" : "6",
  "schoolclass7" : "7",
  "schoolclass8" : "8",
  "schoolclass9" : "9",
  "schoolclass10" : "10",
  "schoolclass11" : "+",

  "curriculum.title" : "hiStories by school class",
  "curriculum.text" : "Here you will find selected hiStories organised by school class. As each federal state has its own curriculum, the categorisation is only roughly possible.",

  "welcome.about" : "about histomania",
  "pricingbutton.text" : "Pricing",

  "licenses": "licenses",

  "users.headingUsers": "Users",
  "addressForm.companyLabel": "Company",
  "checkout.continueToStripe": "Continue to payment provider Stripe",
  "listMenu.nestedListTwitter": "A timeline on",
  "export.lockWarningText": "The export functionality is not available. Learn more about full access",
  "topTaskCnt.title": "Pages with many events",
  "editbookingdetails.event": "Event",
  "addressForm.surnameLabel": "Surname",
  "googleMapContent.overlay": "Overlay",
  "quiz.resultFilterIncorrect": "incorrect",
  "import.here": "here",
  "quiz.pickNumberOfSelectionSingle": "Select one answer",
  "licenseCard.purpose": "Purpose",
  "checkout.payConveniently": "Or pay quickly and conveniently by credit card, Klarna, Bancontact, or eps transfer:",
  "displayStoryDetails.publishNow": "Publish now",
  "addressForm.austria": "Austria",
  "editbookingdetails.reallydelete": "Really delete?",
  "maintenanceeventlisttable.andauernd": "ongoing",
  "addressForm.prenameEmptyError": "First name cannot be empty.",
  "dataset.json": "JSON",
  "histomaniamain.sessionover": "Your session has expired.",
  "licenseCard.paid": "paid",
  "priceCalculator.numStudents": "Number of students",
  "usersPerDay.count": "Count",
  "priceCalculator.numTeachers": "Number of teachers",
  "priceCalculator.calculated": "calculated.",
  "pricing.testAgreement": "Would you like an individual test agreement?",
  "addressForm.streetNumberLabel": "House number",
  "chooseInfoBlock.backgroundColor": "Background color",
  "histomaniamain.pleasewait": "please wait",
  "licenseCard.loginType": "Teacher logins",
  "pricing.subdomain": "Do you need a custom subdomain on histomania.com or are you having funding issues at your school?",
  "priceCalculator.howManyUsers": "How many different users will work with Histomania per year at most?",
  "usersPerDay.newUsers": "New users per day",
  "maintenanceeventlisttable.clickToEdit": "Click on the desired table cell to edit or '...' to go to the details.",
  "embed.showHeader": "Show header",
  "priceCalculator.privateUsers": "private and business users",
  "users.validatedHeading": "Validated",
  "search.from": "from",
  "addressForm.emailLabel": "Email address",
  "topTaskCnt.creator": "Creator",
  "userDetails.id": "ID",
  "addressForm.streetNumberEmptyError": "House number cannot be empty.",
  "displayStoryDetails.mindmap": "Mind map",
  "displayStoryDetails.learnMoreFullAccess": "Learn more about full access.",
  "printdialog.from": "from",
  "topVisited.topPages": "Top visited pages",
  "addressForm.zipCodeLabel": "Postal code",
  "menu.qrCode": "QR Code",
  "mediaFile.videoTooltip": "Show video",
  "qrCodePage.downloadButton": "Download in svg format",
  "chooseMapDialog.newOverlay": "new overlay",
  "mediaFile.quizTooltip": "Show quiz",
  "embed.title": "Embed",
  "pregnancyDialog.enterDate": "Enter the date of your last period or the date of conception to see your individual pregnancy calendar.",
  "pregnancyDialog.loading": "Loading...",
  "addressForm.schoolOrOrgLabel": "School/Organization",
  "priceLegendSchool.from5Teachers": "from 5 teachers",
  "users.newsletterSubscribersText": "Newsletter subscribers, of which",
  "bookingRightsAdmin.notAvailable": "The rights assignment is not available.",
  "topVisited.creationTime": "Creation time",
  "chooseMapDialog.takeover": "takeover",
  "sourceReference.uneImgAlt": "An image of ",
  "googleMapContent.kontrollpunkte": "Control points",
  "licenseCard.username": "Username",
  "googleMapContent.gitter": "Grid",
  "embed.showFab": "Floating Action Button visible",
  "displayStoryDetails.textScrambled": "Letters in texts have been scrambled.",
  "priceCalculator.numUsers": "Number of users",
  "youtube.consentLabel": "I agree that content from YouTube will be displayed to me. This may lead to the transfer of personal data to third parties.",
  "licenseCard.determineUsers": "Here you can determine which users are allowed to use your license during login.",
  "import.csvTextLabel": "CSV text",
  "orderError.orderErrorOccurred": "An error occurred with your order.",
  "deleteDialog.title": "Really delete?",
  "dataset.table": "Table",
  "licenseCard.status": "Status",
  "checkout.paymentReference": "Purpose:",
  "licenseCard.studentLogins": "Student logins",
  "topTaskCnt.events": "Events",
  "quizQuestion.question": "Question",
  "priceLegendSchool.from10Teachers": "from 10 teachers",
  "printdialog.to": "to",
  "chooseMapDialog.selectedPosition": "selected position",
  "addressForm.schoolEmptyError": "School/organization cannot be empty.",
  "histomaniamain.chooseStoryForNewEvent": "Choose the hiStory for the new event.",
  "dummyUserHint.logonHere": "Log in here",
  "users.loadingError": "Error loading user details",
  "checkout.specifyReference": "Please ensure to specify the purpose in each case",
  "zoomableImage.bild": "Image: ",
  "priceCalculator.chooseFutureDate": "Please choose a valid date that is today or in the future.",
  "chooseMapDialog.arrow": "arrow",
  "topVisited.page": "Page",
  "licenseCard.recognizeUsers": "Based on which features can users be recognized who are allowed to log in with this license?",
  "users.validatedText": "validated",
  "embed.showArticle": "Show article",
  "instantFeedback.correctMessage": "Correct! Please click Next to continue.",
  "checkout.configureLicenses": "There you can also configure your licenses.",
  "licenseCard.mailDomain": "Mail domain",
  "chooseMapDialog.or": "or",
  "bookingRightsAdmin.publiclyAccessible": "The new hiStory is publicly accessible.",
  "priceLegendPlus.pricesPerYearPerUser": "Prices per year and user",
  "priceCalculator.totalPrice": "Total Price:",
  "histomaniamain.pressHere": "Press here for 2 seconds to create a new event",
  "print.legendSource": "Source",
  "histomaniamain.whatif": "what if...",
  "licenseCard.paymentPending": "Payment pending",
  "priceLegendSchool.from300Students": "from 300 students",
  "mediaFile.streetViewTooltip": "Show StreetView",
  "addressForm.germany": "Germany",
  "markdowneditfield.mdFormatting": "(Markdown formatting possible)",
  "bookingRightsAdmin.learnMore": "Learn more about unlocking the permissions.",
  "dummyUserHint.notPublished": "You also cannot publish this hiStory.",
  "addressForm.emailInvalidError": "Invalid email address.",
  "checkout.peterRankSoftware": "Peter Rank Software",
  "dummyUserHint.notAddedToAccount": "The hiStory will not be added to your account even if you sign in.",
  "licenseCard.orderDate": "Order date",
  "embed.htmlSnippet": "HTML snippet",
  "addressForm.townEmptyError": "Town must not be empty.",
  "users.privilegesError": "Error setting admin privileges",
  "pricing.freebody": () => <><h2 style={{display: 'inline-block'}}>0,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>00</span> €</h2><br/>free basic version for private, commercial or educational purposes.<br/>Ideal for testing the application.<br/><br/><hr/><br/><ul><li>permanently free</li><li>Embed timelines into own websites</li><li>Create own hiStories</li></ul></>,
  "googleMapContent.transparenz": "Transparency",
  "pregnancyDialog.firstDayLastPeriodTask": "First day of the last period",
  "userDetails.adminPrivileged": "Admin privileged",
  "priceCalculator.atLeastTenStudents": "At least 10 students will be charged.",
  "licenseCard.schoolVidisEduca": "School (VIDIS or EDUCA only)",
  "googleMapContent.durchsichtig": "transparent",
  "topVisited.visits": "Visits",
  "qrCodePage.instruction": "You can save and print this QR code.",
  "chooseMapDialog.click": "Click the path of the arrow and at the end on",
  "checkout.within14Days": "within 14 days to the following account:",
  "topVisited.events": "Events",
  "topTaskCnt.visits": "Visits",
  "bookmarkdialog.name": "Name",
  "priceLegendSchool.from100Students": "from 100 students",
  "users.surnameHeading": "Surname",
  "checkout.accountNumber": "Account number: DE55 7019 0000 0007 5237 42",
  "chooseMapDialog.pointEndInstruction": "Remove points with [Shift + Click].",
  "topTaskCnt.creationTime": "Creation time",
  "pricing.configureAndOrder": "Configure and book",
  "quiz.multipleSelectionTagText": "Multiple choice",
  "quiz.nextQuestionBtn": "Next",
  "priceCalculator.errorOccurred": "An error occurred while submitting the data.",
  "markdowneditfield.insertTable": "Insert table",
  "chooseMapDialog.arrowInstruction": "Click the path of the arrow and at the end on ",
  "histomaniamain.checkconnection": "Please check the internet connection.",
  "despotbirthday.loading": "Loading...",
  "markdowneditfield.preview": "Preview",
  "displayStoryDetails.publishPrompt": "Publish this hiStory so that everyone can find and see it:",
  "chooseMapDialog.newMarker": "new marker",
  "quiz.landingHeaderText": (nrOfQuestions) => nrOfQuestions + " questions",
  "quiz.auswertung": "Evaluation",
"addressForm.townLabel": "City",
"topVisited.creator": "Creator",
"menu.active": "active",
"priceCalculator.profileManagement": "After ordering, you can view the status of your license and configure access under the menu item 'Profile Management | Licenses'.",
"import.warnTitle": "Warning",
"priceLegendSchool.pricesPerYearPerUser": "Prices per Year and User",
"addressForm.streetEmptyError": "Street cannot be empty.",
"qrCodePage.title": "QR Code",
"despotbirthday.title": "Compare Yourself with Despots",
"import.title": "Import",
"userDetails.events": "Events",
"priceCalculator.contactSupport": "Please contact us directly via email at order@histomania.com.",
"menu.onWhatsApp": "via WhatsApp",
"quizAnswer.antwort": "Answer ",
"menu.embedStory": "Embed hiStory",
"addressForm.taxNumberHelper": "If your organization has a tax number",
"histomaniamain.isLoading": "is loading...",
"displayStoryDetails.position": "Position",
"editButton.bearbeiten": "Edit",
"printdialog.widthLabel": "Width [px]",
"quizQuestion.addAnswer": "Add Answer",
"userDetails.lastStoryChange": "last story change",
"priceCalculator.includesTax": "Our prices include 19% German VAT.",
"despotbirthday.prompt": "What if the despots of the 20th century were born with you?",
"chooseInfoBlock.rightMargin": "right margin",
"embed.darkBackground": "Timeline background dark",
"quiz.question": "Question",
"userDetails.public": "public",
"chooseMarkdownDialog.markdown": "Markdown",
"priceCalculator.validFrom": "Valid from",
"chooseMapDialog.overlayStep1": "Step 1:",
"editbookingdetails.newEvent": "New Event",
"instantFeedback.incorrectMessage": "Wrong answer. Please try again.",
"deleteDialog.confirmationText": "Do you really want to delete the hiStory?",
"users.countText": "Users, of which",
"orderError.contactSupport": "Please contact us directly at info@histomania.com.",
"pregnancyDialog.from": "from",
"checkout.noNeedToWait": "You don't need to wait for the booking of your payment to occur.",
"priceLegendSchool.teachers": "Teachers",
"checkout.pleasePayAmount": "Please pay the amount of",
"addressForm.switzerland": "Switzerland",
"pricing.contactUs": "No matter what individual requirement you have, write to us at",
"markdowneditfield.mapEdit": "Edit Map",
"search.to": "to",
"printdialog.barSizeLabel": "Bar height [px]",
"addressForm.taxNumberLabel": "Tax Number",
"usersPerDay.day": "Day",
"licenseCard.plusLicense": "Plus License",
"listMenu.nestedListFacebook": "A Timeline on",
"googleMapContent.undurchsichtig": "opaque",
"listMenu.nestedListMailSubject": "Timeline on",
"bookmarkItem.js.foundStories": (size) => "(Summary of "+size+" hiStories)",
"addressForm.surnameEmptyError": "Last name cannot be empty.",
"addressForm.zipCodeInvalidError": "Postal code must have 5 digits.",
"maintenanceeventlisttable.autoSaveInfo": "Every change is saved immediately as soon as you leave the table cell.",
"filterTimeline.tip": "Tip: First, click on the struck-through eye at 'All' to filter out all events and then gradually add events.",
"priceLegendSchool.students": "Students",
"users.forenameHeading": "First Name",
"chooseInfoBlock.infoblock": "Infoblock",
"printdialog.altText": "Preview",
"quiz.resultFilterAll": "All",
"dummyUserHint.warningText": "Created for test purposes, but it will be deleted after some time.",
"chooseMarkdownDialog.leftMargin": "left margin",
"listMenu.nestedListWhatsApp": "A Timeline on",
"priceCalculator.atLeastOne": "At least one",
"licenseCard.billingAddress": "Billing Address",
"userDetails.name": "Name",
"chooseMarkdownDialog.rightMargin": "right margin",
"priceLegendPlus.hundredOrMoreUsers": "from 100 users",
"quiz.resultFilterCorrect": "correct",
"checkout.findNumberProfile": "You can also find this number under the menu item 'Profile Management | Licenses' and on your invoice.",
"priceCalculator.defineUsers": "There you also define the individual users or user groups that may log in with this license.",
"addressForm.countryEmptyError": "Country cannot be empty.",
"quiz.singleSelectionTagText": "Single Selection",
"licenseCard.userLoginType": "User Logins",
"embed.showBgImage": "Show image in the background of the timeline",
"addressForm.streetLabel": "Street",
"import.description": () => <>Lines that could not be imported remain in the text field after import<br/>You can find a description of the required format</>,
"priceLegendPlus.twentyOneToNinetyNineUsers": "21-99 users",
"export.title": "Export",
"bookmarks.jsSaveBookmark": "save as bookmark",
"priceLegendPlus.oneToFourUsers": "1-4 users",
"pregnancyDialog.whatIf": "what if...",
"pricing.schoolbody": () => <span>from <h2 style={{display: 'inline-block'}}>2,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>40</span> €</h2> / student / year<br/>For registrations via VIDIS, edulog, with school email addresses, or individually configured.<br/><br/>from one teacher and 10 students<br/><hr/><br/><ul><li>Full visibility of paid content</li><li>Creation of private content</li><li>Restricted to school.histomania.com</li><li>Approved</li></ul></span>,
"priceLegendPlus.fiveToTwentyUsers": "5-20 users",
"textField.ariaSearch": "Search",
"menu.onFacebook": "on Facebook",
"addressForm.countryLabel": "Country",
"quizEditor.addQuestion": "Add Question",
"pricing.plusbody": () => <span>from <h2 style={{display: 'inline-block'}}>29,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>90</span> €</h2> / user / year<br/>For private and business users.<br/><br/><hr/><br/><ul><li>Full visibility of paid content</li><li>Creation of private content</li><li>Export option</li></ul></span>,
"chooseInfoBlock.leftMargin": "left margin",
"checkout.bankCode": "Bank Code: GENO DEF1 M01 (Münchner Bank e.G.)",
"users.numHistories": "Number of hiStories",
"despotbirthday.label": "When is your birthday?",
"priceCalculator.perYear": "per year",
"pregnancyDialog.yourPregnancyCalendar": "Your Pregnancy Calendar",
"printdialog.heightLabel": "Height [px]",
"pregnancyDialog.dayOfFertilization": "Day of Fertilization",

  "priceCalculator.licenseConfig": "License configuration for",
  "checkout.toLicenseAdmin": "To license administration",
  "filterTimeline.showIds": "Show IDs of unfiltered",
  "dataset.csvimport": "CSV import",
  "dummyUserHint.here": "here",
  "connections.newConnection": "new connection",
  "quiz.resultPageHeaderText": (correctlength, questionslength) => "You have answered " + correctlength + " out of " + questionslength + " questions correctly.",
  "titlelist.ago": (years) => years + " years ago",
  "chooseMapDialog.newArrow": "new arrow",
  "priceCalculator.purchaseInfo": "To make a purchase, you need a histomania account and must be logged in.",
  "markdowneditfield.convertToBullet": "Convert selected text to bullet list",
  "chooseMapDialog.marker": "marker",
  "despotbirthday.chooseBirthday": "Enter your birthday, and you can see what other despots achieved at your age.",
  "checkout.toLicenseAdminLower": "to license administration",
  "licenseCard.schoolLicense": "School license",
  "userDetails.hiStories": "hiStories",
  "userrightsdialog.userneverloggedin": "The user has never logged in",
  "mediaFile.mapTooltip": "Show map",
  "markdowneditfield.linkWith": "Link selected text with hiStory, event, or filter",
  "addPanoramaButton.title": "Brandenburg Gate",
  "licenseCard.stateVidisEduca": "Federal State (VIDIS or EDUCA only)",
  "checkout.fullAccess": "We have already activated your full access for the next 14 days.",
  "markdowneditfield.videoEdit": "Edit video",
  "topTaskCnt.page": "Page",
  "quiz.pickNumberOfSelection": (numberOfSelection) => "Select " + numberOfSelection + " answers",
  "chooseMapDialog.overlay": "overlay",
  "imageButton.title": "My Image",
  "licenseCard.validity": "Validity",
  "users.emailHeading": "Email",
  "checkout.noNeedToWaitInvoice": "You do not need to wait until you receive the invoice and the payment is processed.",
  "quiz.resultPagePoint": (correctPoints, totalPoints) => "You receive " + correctPoints + " out of " + totalPoints + " points.",
  "users.newsletterHeading": "Newsletter",
  "priceCalculator.foreignOrders": "For orders from abroad, taxes may apply in the recipient country, which the buyer is responsible for paying.",
  "chooseMapDialog.uploadImage": "Upload your image and select the section",
  "addressForm.prenameLabel": "First name",
  "priceCalculator.billingAddress": "Billing address",
  "displayStoryDetails.storyProtected": "This hiStory is protected.",
  "pricing.findSolution": "We will find a mutual solution.",
  "quizQuestion.addExplanation": "Click here to add an explanation",
  "licenseCard.showAddress": "Show billing address",
  "markdowneditfield.convertToQuote": "Convert selected text to quote",
  "userDetails.lastEventChange": "last event change",
  "displayStoryDetails.map": "Map",
  "checkout.reviewOrder": "We will review your order and send an invoice with payment information to the specified email address.",
  "quiz.startQuizBtn": "Start the quiz",
  "pricing.vatIncluded": "Our prices include VAT.",
  "priceCalculator.schools": "Schools and institutions",
  "dummyuserhint.js706662830": () => <>Without registration, you can create a hiStory for <b>testing purposes</b> only, but it will be deleted after some time.</>,
  "pregnancyDialog.firstDayLastPeriod": "First day of last period",
  "markdowneditfield.hr": "Insert horizontal line",
  "menu.onTwitter": "on Twitter",
  "menu.france": "French",
  "menu.italy": "Italian",
  "nomoretwitter": () => <>
    So far, you've logged in here using your Twitter/X account.<br/>
    Simply request a new password via "Forgot Password" to your Twitter/X email address and then log in directly with us.
  </>,
  "langNotAvailable": "Sorry, the desired language is not available for ",
  "and": "and",
  "menu": "Menu",
}

export default res;
