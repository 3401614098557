import React from "react";
import Typography from "@material-ui/core/Typography";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ScheduleIcon from "@material-ui/icons/Schedule";
import RoomIcon from "@material-ui/icons/Room";
import Filter from "../images/icons/filter";

const res = {
"newStory": "nouvelle hiStory",
"newStoryTooltipHeader": "Créer une nouvelle hiStory",
"newStoryTooltipBody": "Crée le cadre pour ton récit, la chronologie et les événements.",
"newEventTooltipHeader": "Créer un nouvel événement",
"newEvent": "Nouvel événement",
"newEventTooltipBody1": "Crée un nouvel événement dans la timeline.",
"newEventTooltipBody2": "Crée l'événement via le menu contextuel ",
"newEventTooltipBody3": "(clic gauche sur une zone libre)",
"newEventTooltipBody4": " dans la timeline, si tu souhaites pré-définir le moment.",
"search": "Rechercher",
"searchEventsAndStories": "Rechercher des événements et des hiStories",
"searchEventsAndStoriesTooltip1": "- Recherche des événements dans la timeline en tapant",
"searchEventsAndStoriesTooltip2": "- Recherche des hiStories",
"filter": "Filtrer",
"filterEvents": "Filtrer les événements",
"filterEventsTooltip1": "Afficher ou masquer les événements selon différents critères",
"measure": "Mesurer les temps",
"measureTooltip": "Mesurer les temps dans la timeline",
"help": "Aide",
"more": "plus...",
"loginInfoNotLoggedIn": "Tu n'es pas connecté",
"loginInfoDoLoginHere": "Connecte-toi ici.",
"login": "Connexion",
"loginWithGoogle": "Se connecter avec Google",
"loginWithFacebook": "Se connecter avec Facebook",
"loginWithVIDIS": "Se connecter avec un compte scolaire (VIDIS)",
"loginWithFobizz": "Se connecter avec Fobizz",
"loginWithEdulog": "Se connecter avec un compte scolaire (edulog)",
"storiesNearby": "hiStories à proximité",
"searchStories": "Rechercher des hiStories",
"myStories": "Mes hiStories",
"recentlySeen": "Vu récemment",
"usedImagesAndSources": "Les images utilisées et leurs sources.",
"sourcesOnlyGiven": "Les sources ne sont indiquées que si l'auteur les a fournies.",
"imageSourceReferences": "Références des sources d'images",
"settings": "Paramètres",
"separatorTimelineDesc": "Séparateur pour la timeline/texte de l'article",
"horizontal": "horizontal",
"vertical": "vertical",
"showSidewaysDuringEventSelection": "afficher latéralement lors de la sélection d'un événement",
"alwaysSuperimpose": "toujours afficher en superposition",
"automatic": "automatique",
"detailView": "Vue détaillée",
"eventLabeling": "Étiquetage des événements",
"fullLabeling": "Étiquetage complet",
"maxAsLongAsBar": "maximum aussi long que la barre d'événement",
"showTimelineBackgroundImage": "Image de fond de la timeline",
"showTimelineBackgroundImageTrue": "afficher",
"showTimelineBackgroundImageFalse": "ne pas afficher",
"timelineTheme": "Thème de fond de la timeline",
"dark": "sombre",
"bright": "clair",
"chooseStory": "Choisir une hiStory",
"close": "Fermer",
"save": "Enregistrer",
"ok": "OK",
"cancel": "Annuler",
"yes": "Oui",
"no": "Non",
"delete": "Supprimer",
"copy": "Copier",
"edit": "Éditer",
"back": "Retour",

"understoodAndSave": "Compris et enregistrer",
"error": "Erreur",
"errorInternal": () => (<>Une erreur interne inconnue s'est produite.<br/>Veuillez contacter un interlocuteur de histomania.</>),
"errorMailOrPwdNotFound": (fatLink, showRequestNewPassword) => (<>Votre adresse e-mail ou le mot de passe saisi ne nous sont pas connus.<br/>Si vous avez oublié votre mot de passe, veuillez en demander un nouveau <span style={fatLink} onClick={showRequestNewPassword}>ici</span>.</>),
"msgPasswordSent": () => (<>Nous vous avons envoyé un e-mail avec un lien de confirmation.<br/>Veuillez cliquer sur ce lien pour que nous puissions vous envoyer un nouveau mot de passe.</>),
"msgNotFound": () => (<>L'événement demandé n'a pas été trouvé.<br/>Veuillez contacter un interlocuteur de histomania.</>),
"errorNetwork": () => (<>Une erreur s'est produite.<br/>Veuillez vous assurer que vous avez une connexion Internet fonctionnelle.</>),
"msgAccountCreated": () => (<>Votre compte a été créé.<br/>Nous vous avons envoyé vos identifiants par e-mail.<br/>Veuillez également vérifier votre dossier de spam.</>),
"errorDuplicateUserEntry": () => (<>Nous n'avons pas pu créer un nouveau compte car l'utilisateur existait déjà dans notre base de données.<br/>Veuillez vous connecter avec vos informations existantes ou demander un nouveau mot de passe sous "Mot de passe oublié".</>),
"errorUserNotFound": () => (<>L'utilisateur n'existe pas.</>),
"errorUnknown": () => (<>Une erreur inconnue s'est produite.<br/>Veuillez contacter un interlocuteur de histomania.</>),
"titleNewPasswordRequested": "Nouveau mot de passe demandé",
"titleAccountCreated": "Création du compte",
"or": "ou",

  "createAccount": "Créez ici gratuitement votre nouveau compte histomania avec quelques informations",
  "exportToExcel": "Exporter les données vers MS-Excel",
  "exportToJSON": "Exporter les données vers JSON",
  "addToWebsite": "Ajoutez les hiStories actuellement affichées à votre site web en insérant le code ci-dessous.",
  "codeMustBeInBodyTag": "Le code doit se trouver dans la balise body de votre document HTML.",
  "embedAcceptAGB": "En intégrant des contenus de histomania sur votre site ou application, vous acceptez les conditions générales d'utilisation de histomania.",
  "permissions": "Autorisations",
  "profile": "Profil",
  "username": "Nom d'utilisateur",
  "firstName": "Prénom",
  "lastName": "Nom",
  "company": "Institution",
  "country": "Pays",
  "state": "État/Province",
  "idprovider": "Fournisseur d'ID",
  "role": "Rôle",
  "inputFirstName": "Veuillez entrer votre prénom",
  "inputSurName": "Veuillez entrer votre nom",
  "newsLetterInfo": "La newsletter est publiée environ 5 à 6 fois par an et vous informe des nouveautés et histoires sélectionnées.",
  "subscribeNewsletter": "S'abonner à la newsletter",
  "loading": "chargement",
  "changePassword": "Changement de mot de passe",
  "pwdChangeSuccessful": "Votre mot de passe a été changé avec succès.",
  "pwdChangeErr": "Erreur lors du changement de mot de passe.",
  "pwdLength": "Le mot de passe doit contenir au moins 8 caractères.",
  "pwdRepetitionError": "Le mot de passe répété est différent du premier.",
  "pwdChange": "Changer le mot de passe",
  "pwdOld": "Ancien mot de passe",
  "pwdNew": "Nouveau mot de passe (min. 8 caractères)",
  "pwdNewRepetition": "Nouveau mot de passe (répétition)",
  "showWikipediaTooltip": "Afficher l'article Wikipédia",
  "whatIf": "Et si...",
  "changeTime": "Changer l'heure",
  "moveTimelineFromRefDate": "Déplacez la timeline d'une date de référence à une autre :",
  "moveTimelineByDuration": "Ou déplacez-la d'une durée spécifique :",
  "noteTimelineIsNotSaved": "Remarque : la timeline modifiée n'est pas enregistrée.",
  "moveMeasuringStick": "Déplacez le curseur de mesure à l'heure indiquée",
  "moveMeasuringStickBy": "Déplacer le curseur de mesure de :",
  "moveMeasuringStickTo": "Déplacer le curseur de mesure à :",
  "toPast": "dans le passé",
  "toFuture": "dans le futur",
  "imageWithLicenseImg": "***Image:***",
  "dragNdropHint": "Glissez-déposez un fichier ou cliquez pour ajouter une image",
  "maxFileSize": "Taille maximale du fichier :",
  "info": "Info",
  "attention": "Attention",

 "articleOnWikipedia": "article sur Wikipedia",
"wikidataRecord": "enregistrement Wikidata",
"showLocationOnGoogleMaps": "montrer l'emplacement sur Google Maps",
"showStoriesNearby": "plus d'histoires à proximité",
"showStoriesAtThatTime": "qu'y avait-il d'autre à cette époque ?",
"showVideosOnYoutube": "vidéos sur YouTube",
"showBooksOnAmazon": "livres sur Amazon",
"showDetails": "afficher les détails",
"details": "détails",
"now": "maintenant",
"closeWithSave": "Enregistrer avant de fermer ?",
"closeWithoutSaveQuestion": () => (<>Cliquez sur 'Oui' pour enregistrer vos modifications.<br/>Sinon, vos données modifiées seront perdues.</>),
"seconds": "secondes",
"minutes": "minutes",
"hours": "heures",
"days": "jours",
"untilNow": "jusqu'à maintenant",
"secondsUntilNow": "secondes jusqu'à maintenant",
"minutesUntilNow": "minutes jusqu'à maintenant",
"hoursUntilNow": "heures jusqu'à maintenant",
"daysUntilNow": "jours jusqu'à maintenant",
"unknownDuration": "durée inconnue",
"oClock": "heures",
"pastOrFutureDuration": (isPast, duration) => ((isPast ? "il y a " : "dans ") + duration),
"startType": "type de début",
"endType": "type de fin",
"pointInTime": "point dans le temps",
"born": "né",
"earliest": "au plus tôt",
"start": "début",
"origin": () => (<span><b>Origine</b><br/><Typography variant="caption">ou fondation/création</Typography></span>),
"discovery": "découverte",
"publication": "publication",
"firstDescription": "première description",
"baptized": "baptisé",
"premiere": "première",
"opened": "ouvert",
"activeSince": "actif depuis",
"died": "décédé",
"latest": "au plus tard",
"end": "fin",
"decommissioning": "déclassement",
"dissolved": "dissous",
"lost": "perdu depuis",
"activeUntil": "actif jusqu'à",
"discontinued": "arrêté",
"closed": "fermé",
"precision": "précision",
"1000000000years": "1 milliard d'années",
"100000000years": "100 millions d'années",
"10000000years": "10 millions d'années",
"1000000years": "1 million d'années",
"100000years": "100 000 ans",
"10000years": "10 000 ans",
"millenium": "millénaire",
"century": "siècle",
"decade": "décennie",
"year": "année",
"month": "mois",
"day": "jour",
"hour": "heure",
"minute": "minute",
"exact": "exact",
"duration": "durée",
"years": "années",
"mon.": "mois",
"h.": "h.",
"min.": "min.",
"noTimeChosen": "aucune date sélectionnée",
"today": "aujourd'hui",

 "weekdays": () => (["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"]),
  "internalServerError": () => (<div>Une erreur de serveur interne s'est produite.<br/>Veuillez contacter un représentant de histomania.</div>),
  "userNotAcknowledged": () => (<div>L'utilisateur existe dans notre système, mais il n'a pas encore été confirmé par vous via le lien dans l'e-mail.</div>),
  "userOrPwdNotInSystem": () => (<div>Nous ne connaissons pas l'utilisateur ou le mot de passe saisi.</div>),
  "oldPasswordUnknown": () => (<div>Nous ne connaissons pas l'ancien mot de passe.</div>),
  "pwdGuideline": () => (<div>Les données saisies ne respectent pas les directives.</div>),
  "noDataRefresh": () => (<div>Les données n'ont pas pu être mises à jour.</div>),
  "emailAlreadyAvailable": () => (<div>L'adresse e-mail saisie est déjà disponible</div>),
  "userNotCreated": () => (<div>L'utilisateur n'a pas pu être créé</div>),
  "reCaptchaError": () => (<div>Google reCaptcha : Vous n'avez pas pu être identifié en tant qu'humain.</div>),
  "notPlausible": () => (<div>Les saisies ne sont pas plausibles.</div>),
  "sessionInvalid": () => (<div>La session n'est plus valide.<br/>Veuillez vous reconnecter.</div>),
  "unknownError": (errorCode) => (<div>Une erreur inconnue avec le numéro {errorCode} s'est produite.<br/>Veuillez contacter un représentant de histomania.</div>),
  "hideStory": () => (<><Typography color="inherit">Masquer hiStory</Typography>
    <hr/>
    Vous retrouverez rapidement cette hiStory après l'avoir masquée en utilisant la recherche sous <br/><em>'vu récemment'</em>.</>),
  "showStory": () => (<><Typography color="inherit">Afficher hiStory</Typography>
    <hr/>
    Ouvrir la hiStory dans un nouvel onglet et l'ajouter à la timeline existante en parallèle.</>),
  "showStory2": "Afficher hiStory",

 "restrictYourSearch": (totalcnt) => ("Trop de résultats (" + totalcnt + "). Veuillez affiner votre recherche."),
"allowGeoLocation": "Veuillez d'abord autoriser histomania à accéder à votre emplacement dans les paramètres.",
"everytime": "à tout moment",
"everywhere": "partout",
"myNeighbourhood": "près de moi",
"inNeighbourhood": "à proximité de",
"search2": "Recherche",
"currentView": () => (<>vue<br/>actuelle</>),
"recentlySeen2": () => (<>vu<br/>récemment</>),
"myStories2": () => (<>mes<br/>hiStories</>),
"searchPhrase": "Mots clés",
"inputSearchPhrase": "Saisir le mot clé",
"searchPhraseSample": "par ex : Physicien Albert",
"minimumCharacters": (minSearchWordCharCnt) => ("au moins " + minSearchWordCharCnt + " caractères par mot clé"),
"when": "Période de l'événement",
"where": "Lieu",
"inNeighbourhoodOf": "à proximité de ...",
"chosenPosition": "position choisie",
"latitude": "Latitude",
"longitude": "Longitude",
"searchTimelines": "Rechercher des hiStories",
"searchStoriesInNeighbourhood": "Rechercher des hiStories à proximité",
"resultsFromLocalSearch": "Événements de la recherche locale (cliquez pour plus de détails) :",
"recommended": "hiStories recommandées",
"todaysBirthdays": "Anniversaires aujourd'hui",
"todaysDeathdays": "Décès aujourd'hui",
"anniversaries": "Anniversaires d'événements aujourd'hui",
"hideAll": "Tout masquer",
"inCurrentView": "dans la vue actuelle",
"privateTimeline": "hiStory privée",
"less": "moins...",
"image": "Image",
"logoURL": "https://" + window.location.host,
"wikiLicenseCCSA": (site) => (<>Source : fr.wikipedia.org - Le texte est disponible sous la licence <a target="_blank" rel="noopener noreferrer" href={"https://fr.wikipedia.org/wiki/Wikipédia:Licence_CC-BY-SA"}>« Creative Commons Attribution-Share Alike »</a>.<br/>Voici la liste des <a target="_blank" rel="noopener noreferrer" href={"https://xtools.wmflabs.org/articleinfo-authorship/fr.wikipedia.org/" + site + "?uselang=fr"}>auteurs</a>.</>),
"wikiURL": "https://fr.wikipedia.org/wiki/",
"infiniteCalendarHeaderFormat": "dddd, D MMM",
"resetfilter": "Réinitialiser le filtre",
"maintainEventList": "Gérer les événements sous forme de tableau",
"importcsv": "Importer depuis CSV",
"import": "Importer",

  "prenameError": "Veuillez entrer votre prénom sans chiffres ni caractères spéciaux. Un prénom doit comporter au moins deux caractères.",
  "surnameError": "Veuillez entrer votre nom de famille sans chiffres ni caractères spéciaux. Un nom de famille doit comporter au moins deux caractères.",
  "mailError": "Veuillez entrer une adresse e-mail valide afin que nous puissions vous envoyer un lien de confirmation.",
  "fonts": "Polices...",
  "bold": "gras",
  "italic": "italique",
  "underline": "souligné",
  "code": "Code",
  "unorderedList": "liste non ordonnée",
  "orderedList": "liste ordonnée",
  "quote": "citation",
  "codeblock": "bloc de code",
  "divider": "ligne horizontale",
  "alignment": "alignement du texte...",
  "addImage": "ajouter une image",
  "addVideo": "ajouter une vidéo",
  "addMap": "ajouter une carte",
  "addPanorama": "ajouter une vue à 360°",
  "addQuiz": "ajouter un quiz",
  "addInfoblock": "ajouter un bloc d'info",
  "addLink": "lier le texte sélectionné à un site web",
  "addEvent": "lier le texte sélectionné à un événement dans la timeline",
  "addStory": "lier le texte sélectionné à l'histoire",
  "addFilter": "lier le texte sélectionné à un filtre",
  "landingpage_title": "La plateforme pour l'histoire sur la ligne du temps",
  "welcome.js735054413": "Découvrez le monde de l'histoire dans hiStories, la compilation interactive et multimédia de chronologies, textes, images, vidéos, quiz, vues à 360° et cartes.",
  "welcome.js142706916": "Créez vos propres chronologies pour votre CV, une chronique locale ou un support d'apprentissage pour une expérience inoubliable.",
  "welcome.js737110502": "dans ma région",
  "welcome.js455445377": "explorer",
  "welcome.js695746560": "Découvrez le monde de l'histoire dans hiStories, la compilation interactive et multimédia de chronologies, textes, images et quiz.",
  "explanation": () => (<>
    Vivez l'histoire d'une nouvelle manière avec histomania, une plateforme interactive qui vous facilite la narration et la compréhension des histoires.
    <br/>
    <br/>
    Ne perdez jamais de vue le temps et le lieu en naviguant à travers des milliers d'histoires et de biographies.
    <br/>
    Contrairement aux récits hypertextuels classiques, où l'on ne peut que sauter d'un article à un autre, les textes dans histomania contiennent des liens supplémentaires permettant de suivre des événements sur une ligne de temps ou une carte.
    <br/>
    <br/>
    <PostAddIcon/>&nbsp;&nbsp;ouvre une nouvelle hiStory
    <br/>
    <ScheduleIcon/>&nbsp;&nbsp;montre l'événement dans la ligne du temps
    <br/>
    <RoomIcon/>&nbsp;&nbsp;indique la position de l'événement sur la carte
    <br/>
    <Filter/>&nbsp;&nbsp;filtre les événements dans la timeline et la chronologie
    <br/>
    <br/>
    Explorez les échelles de temps en comparant les lignes de temps de différentes hiStories et mesurez les intervalles entre les événements au sein de ces lignes de temps.
    <br/>
    Grâce à la comparaison et à la mesure, vous pourrez appréhender de manière nouvelle les périodes et les époques de l'histoire.
    <br/>
    <br/>
    Utilisez des cartes interactives, qui peuvent inclure non seulement des épingles pour les positions, mais aussi des chaînes de flèches pour les itinéraires et des superpositions pour représenter des zones.
    <br/>
    Celles-ci peuvent même être animées et avoir un degré de transparence choisi par vous, vous offrant une compréhension unique des changements de frontières.
    <br/>
    <br/>
    Découvrez des histoires dans votre environnement actuel ou lors de vos visites dans des villes inconnues d'un simple clic.
    Trouvez les histoires pertinentes dans les musées via un code QR.
    <br/>
    <br/>
    Vous pouvez même intégrer vos hiStories préférées dans votre propre page d'accueil.
    <br/>
    <br/>
    Que vous souhaitiez publier vos propres hiStories ou simplement naviguer dans l'histoire, histomania est le compagnon idéal pour les élèves en cours d'histoire, les étudiants en sciences humaines, les historiens, les chroniquiers locaux et tous ceux qui aiment l'histoire ou l'actualité en politique et dans le monde.
  </>),
  "topfacts_wiki": () => (<span>Y a-t-il un article correspondant sur Wikipédia, ou des vidéos sur YouTube concernant une histoire ou un événement historique?<br/>Ou si un événement est géographiquement déterminé, souhaitez-vous le voir sur une carte Google Maps, et peut-être même vous y rendre directement?<br/>En un seul clic, vous êtes redirigé des détails de histomania vers la page correspondante.</span>),
  "topfacts.js415020171": "naviguer",
  "topfacts.js111683501": "comparer",
  "topfacts.js56475656": "intégrer",
  "topfacts.js939157228": "gratuitement",
  "topfacts.js617723463": "mesurer",
  "topfacts.js692398414": "créer",
  "topfacts.js899063949": "questions fictives",

  "topfacts.js152860411": "partager",
  "topfacts.js55830277": "lié",
  "topfacts.js335278155": "rechercher",
  "topfacts.js150418448": "indépendant de l'appareil",
  "topfacts.js820645511": "du Big Bang à la fin du monde",
  "topfacts.js645936163": "En liant des événements historiques, tu peux naviguer d’une histoire à une autre.",
  "topfacts.js751322491": "histomania te permet de superposer plusieurs chronologies, et ainsi de les rendre comparables.",
  "topfacts.js921529656": "Souhaites-tu intégrer des histoires sur ton propre site Web ou blog? En savoir plus ici.",
  "topfacts.js895080977": "histomania est gratuit dans sa version de base. Peu importe que tu l’utilises à des fins privées, commerciales ou éducatives.",
  "topfacts.js948823105": "De combien d'années Marilyn Monroe est-elle plus âgée que James Dean? Quel âge avait Einstein lorsqu’il a reçu le prix Nobel? Mesure les durées qui t'intéressent sur la chronologie simplement avec un pied à coulisse intégré.",
  "topfacts.js107256625": "Écris ta propre histoire. Présente les événements historiques de manière à montrer ce qui est important pour toi. Ou décris ton parcours de vie sur une chronologie.",
  "topfacts.js102762366": "Et si... tu étais né à la même époque que Goethe? Crée ton parcours de vie et déplace-le pour pouvoir le comparer avec celui des autres.",
  "topfacts.js373990748": "Travaille en collaboration avec d'autres sur une chronologie et décide par le biais d'autorisations qui peut voir ou modifier ton histoire.",
  "topfacts.js809530473": "En plus de la recherche par mots-clés, tu peux aussi chercher ce qui s'est passé à certaines périodes à certains endroits. Es-tu dans un lieu étranger et veux-tu en savoir plus sur l'histoire de la région? Clique sur 'Qu’y a-t-il à proximité?' et recherche avec un smartphone équipé de GPS les événements dans les environs.",
  "topfacts.js15371141": "Que ce soit sur un smartphone, une tablette ou un PC, avec histomania tu as une application qui s'adapte à tous les appareils, que ce soit la taille de l'écran ou le type d'entrée par souris ou gestes tactiles.",
  "topfacts.js678201177": "histomania peut traiter des périodes incroyablement larges avec une précision réglable à la minute près. Ainsi, les époques de l’histoire de la Terre comme l’histoire du premier alunissage peuvent être représentées de manière significative.",
  "contact": "Contact",
  "phone": "Téléphone",
  "newHiStoryMessage": () => (<span>Votre nouvelle hiStory est maintenant créée.<br/>Ajoutez des événements maintenant.</span>),
  "histomaniamain.js218992075": "Veuillez indiquer au moins un administrateur.",
  "menu.js844059924": "Nouvelle hiStory",
  "storydetailsmain.js785302558": "nouvelle hiStory",
  "storydetailsmain.js382589702": "hiStory",
  "mainFab.js309680386": "modifier hiStory",
  "mainFab.js593675655": "entretenir les événements dans un tableau",
  "mainFab.js968262525": "ajouter un événement",
  "mainFab.js943257132": "nouvelle hiStory",
  "bookmarks.js93045028": "Marque-pages",
  "menu.js505082777": "Nouvel événement",
  "menu.js756551371": "Et si...?",
  "menu.js663038438": "Annuler 'Et si...?'",
  "menu.js439620799": "Marque-pages",
  "menu.js157014000": "Impression et exportation d'image",
  "menu.js555525792": "Import/Export",
  "menu.js923812869": "Gestion de profil",
  "menu.js450602741": "Mon profil",
  "menu.js984392851": "Changer de mot de passe",
  "menu.js498764961": "Langue",
  "menu.js869701443": "Allemand",
  "menu.js503793771": "Anglais",
  "menu.js161223496": "Ukrainien",
  "menu.js961199351": "Mes paramètres",
  "menu.js165576261": "Se déconnecter",
  "menu.js972296370": "Se connecter",


 "bargroup.js313011152": "Désignation",
"bargroup.js999078620": "nouvelle désignation",
"barshape.js677799618": "barre épaisse",
"barshape.js210728036": "barre fine",
"barshape.js708427194": "accolade",
"barshape.js853386386": "fond transparent",
"barshape.js709767519": "petite étoile",
"barshape.js620435244": "étoile",
"barshape.js170641551": "petit cercle",
"barshape.js649456610": "cercle",
"barshape.js494726977": "nuage",
"barshape.js735933567": "bulle de dialogue",
"barshape.js931763133": "texte centré",
"barshape.js931763134": "uniquement la ligne de base",
"barshape.js509994468": "petit document",
"barshape.js848987645": "document",
"barshape.js786118528": "petit soleil",
"barshape.js560723628": "soleil",
"barshape.js624588809": "petite croix",
"barshape.js33818872": "croix",
"barshape.js597772577": "petite flèche gauche",
"barshape.js170062957": "flèche gauche",
"barshape.js731660847": "petite flèche droite",
"barshape.js233359132": "flèche droite",
"dataset.js729839193": "erreur dans la description",
"dataset.js345733057": "désignation",
"dataset.js850812704": "unité",
"descriptionBlock.js240405443": "description",
"descriptionBlock.js884402762": "Vous pouvez également insérer des images et des vidéos ici.",
"designBlock.js426292766": "conception",
"designBlock.js902377753": "C'est ainsi que l'événement apparaît sur la chronologie.",
"imageBlock.js428756050": "image",
"imageBlock.js604367201": "Cette image apparaîtra sur la chronologie. Vous pouvez insérer d'autres images dans la description.",
"imageBlock.js399000806": "licence d'image",
"imagechooser.js38403923": "licence d'image",
"innerevents.js238036015": "Temps internes",
"sourcereferenceblock.js99152413": "références",
"sourcereferenceblock.js484508229": "Listez ici la/les source(s) qui prouvent l'événement.",
"timeBlock.js186865327": "temps",
"timeBlock.js929675741": "Pour les temps avant Jésus-Christ, placez un signe moins devant l'année, par ex. 21.04.-753",
"timeBlock.js910792715": "moment",
"timeBlock.js134397445": "période",
"titleBlock.js239194644": "titre",
"titleBlock.js47802846": "Vous pouvez utiliser plusieurs lignes.",
"wikipediareference.js851003152": "page Wikipédia allemande (ou ajouter l'abréviation du pays entre crochets, par exemple [uk])",

 "bookmarkitem.js608408822": "Supprimer",
"story.js915248213": "Mon hiStory",
"drafteditortoolbar.js858681026": "Police",
"drafteditortoolbar.js823346092": "Paragraphe",
"drafteditortoolbar.js97337207": "Médias",
"drafteditortoolbar.js82711167": "Liens",
"chooseImageDialog.js542504966": "Titre",
"chooseMapDialog.js901660970": "Désignation de la carte",
"chooseMapDialog.js589922616": "Désignation",
"chooseMapDialog.js87695793": "URL Google Maps",
"chooseMapDialog.js10384243": "Description de l'image et licence",
"chooseStreetViewDialog.js854616579": "Titre",
"chooseStreetViewDialog.js119725327": "Code d'intégration Street View",
"chooseVideoDialog.js778434054": "Titre",
"chooseVideoDialog.js671606307": "URL ou ID YouTube",
"chooseVideoDialog.js799772333": "Commencer à la seconde",
"AddVideoButton.js665672521": "Créer une frise chronologique gratuitement",
"maintenanceEventList.js998639033": "Événements",
"filtertimeline.js710501750": "Filtrer les événements",
"searchfield.js825023426": "Rechercher des hiStories avec ce terme",
"searchfield.js18900516": "hiStories autour de moi",
"searchfield.js363834300": "Rechercher des hiStories par temps et lieu",
"searchfield.js644144191": "Mes hiStories",
"searchfield.js660560315": "hiStories récemment consultées",
"searchfield.js961730789": "Filtrer la frise chronologique actuelle avec ce terme",
"searchfield.js867502466": "Filtrer la frise chronologique actuelle",
"searchfield.js829902906": "Suggestions de recherche",
"histomaniamain.js781485964": "Mon événement",
"histomaniamain.js702299662": "Veuillez d'abord ouvrir ou créer une hiStory pour ajouter l'événement.",
"histomaniamain.js197615179": "Attention en partageant votre URL.\nVotre hiStory est privée et ne peut pas être vue par tous les destinataires.",
"histomaniamain.js12025145": "Veuillez fournir un titre d'au moins trois caractères pour votre hiStory.",
"histomaniamain.js310062970": "Pour enregistrer la hiStory pendant une période prolongée, vous devez vous connecter.",
"histomaniamain.js5248846": "Veuillez d'abord ouvrir une hiStory que vous pouvez modifier pour y copier l'événement.",
"histomaniamain.js513585760": "aller à",
"import.js261569334": "Veuillez ouvrir d'abord une hiStory pour y déposer les événements.",
"import.js67411235": "Sélectionnez la hiStory dans laquelle les événements doivent être déposés.",
"login.js758426829": "Connectez-vous",
"login.js779088926": "E-mail",
"login.js163307733": "Mot de passe",
"login.js293038552": "Rester connecté",
"login.js116720706": "Pas encore de compte?",
"login.js319559879": "Prénom",
"login.js278946069": "Veuillez indiquer un prénom",
"login.js897560837": "Nom",
"login.js237687098": "Veuillez indiquer un nom",
"login.js457756656": "E-mail",
"login.js692708124": "Veuillez fournir un nom d'utilisateur valide",
"login.js368777066": "Mot de passe oublié?",
"login.js327950210": "E-mail",
"login.js493155713": "Connexion",
"editMediaDialog.js254758074": "Liste des médias",
"editMediaDialog.js106456795": "JSON",
"markdowneditfield.js110445871": "Modifier la vue panoramique",
"markdowneditfield.js117272661": "Modifier le quiz",
"markdowneditfield.js848013488": "Modifier l'élément multimédia",
"lazygooglemap.js947359452": "J'accepte que le contenu de Google Maps soit affiché. Cela peut entraîner la transmission de données personnelles à des tiers.",
"QuizEditorTitlePage.js823814415": "Titre",
"menu.js893403567": "Partager",
"menu.js932778276": "en tant qu'e-mail",
"menu.js556545983": "Exporter les données",
"menu.js413767225": "Importer des données",
"menu.js990897862": "Aide",
"menu.js171939370": "par ",


  "print.js570625276": "La largeur minimale est de ",
  "print.js612051645": "La largeur maximale est de ",
  "print.js574630162": "La hauteur minimale des barres est de ",
  "print.js760791437": "La hauteur maximale des barres est de ",
  "print.js758964558": "Le dépassement maximal a été dépassé.",
  "print.js375853243": "Le dépassement ne doit pas être négatif.",
  "print.js15990392": "Il doit y avoir au moins une partie horizontale à imprimer.",
  "print.js556329669": "Il doit y avoir au moins une partie verticale à imprimer.",
  "print.js833055774": "L'image ne doit pas être découpée en plus de 100 parties.",
  "printdialog.js85755474": "Dépassement dû à l'étiquetage (",
  "printdialog.js341020472": "fond transparent",
  "printdialog.js665169354": "dessiner l’ombre",
  "printdialog.js605134089": "horizontal",
  "printdialog.js839026585": "vertical",
  "displaystorydetails.js158045992": "Sources",
  "addstorydescriptor.js92624992": "hiStories intégrées",
  "addstorydescriptoreditdialog.js502574362": "Groupe",
  "addstorydescriptoreditdialog.js124818429": "ID de la hiStory",
  "addstorydescriptoreditdialog.js117860561": "ID d'événements non filtrés",
  "addstorydescriptoreditdialog.js777122119": "Position",
  "backgroundimageinput.js816937029": "Image",
  "backgroundimageinput.js218259721": "Cette image sert de fond à la timeline et apparaît dans la recherche. Vous pouvez insérer d'autres images dans la description.",
  "backgroundimageinput.js429573179": "Licence de l'image",
  "userrightsdialog.js70424287": "Rechercher un utilisateur",
  "userrightsdialog.js480394996": "E-mail",
  "userrightsdialog.js216158241": "Veuillez entrer une adresse e-mail valide",
  "bookingrightsadministration.js589033383": "Permissions",
  "bookingrightsadministration.js546709873": "tout le monde peut lire la hiStory",
  "descriptioninput.js517543907": "Description",
  "dummyuserhint.js499234330": "Vous n'êtes pas connecté",
  "headlineinput.js150642075": "Titre",
  "headlineinput.js593049979": "Titre de la hiStory",
  "headlineinput.js477282705": "Sous-titre",
  "newstoryhint.js799008560": "Premiers pas",
  "sourcereferenceinput.js783166353": "Références des sources",
  "wikipediainput.js705594377": "Contenus avancés",
  "search.js726564732": "Recherche",
  "search.js671154469": "à tout moment",
  "durationpanel.js274332505": "durée inconnue",
  "searchfield.js807364549": "Rechercher dans la timeline actuelle",
  "histomaniamain.js734544182": "La hiStory est enregistrée uniquement pour la durée de votre session.",
  "menu.js698756284": "Les modifications précédemment effectuées à titre de test seront réinitialisées",
  "userrightsdialog.js937814759": "Modifier la permission",
  "bargroup.js45524065": "Modifier l'étiquette partout",
  "sourceReferenceBlock.js304249147": "Fournir les références des sources pour l'événement.",
  "timeBlock.js20893151": "Temps intérieurs...",


"wikipediareference.js907588328": "Je souhaite intégrer un article Wikipédia.",
"bookmarkdialog.js412170740": "Ajouter un signet",
"bookmarkdialog.js929413458": "Vous n'êtes pas connecté. Vos signets ne seront valides que pendant environ un jour.",
"bookmarks.js475765892": "enregistrer les chronologies ouvertes comme signets",
"bookmarks.js306859711": "Vraiment supprimer ?",
"cookieconsent.js905029185": "Nous utilisons des cookies et des entrées localStorage. Vous pouvez ajuster vos paramètres à tout moment via le menu de l'application. Si vous ne souhaitez pas accepter tous les cookies ou entrées localStorage, ou si vous souhaitez en savoir plus, cliquez sur 'Afficher plus'.",
"cookieconsent.js352836975": "Afficher plus",
"cookieconsent.js243259253": "Fermer",
"cookieconsent.js645797755": "Accepter tous les cookies",
"schoolcookieconsent.js905029185": () => <>Nous utilisons uniquement des cookies techniquement nécessaires et des entrées localStorage sur schule.histomania.com qui ne peuvent pas être refusées.<br/>Cela inclut un cookie de session et des entrées localStorage qui enregistrent la sélection de langue, les identifiants pour l'utilisateur afin qu'il ne doive pas se reconnecter, ainsi que la confirmation de cette fenêtre.<br/></>,
"schoolcookieconsent.js645797755": "OK",
"drafteditortoolbar.js747935826": "Police",
"drafteditortoolbar.js616605922": "Paragraphe",
"drafteditortoolbar.js894699961": "Médias",
"drafteditortoolbar.js109669568": "Liens",
"chooseMapDialog.js417538297": "Étape 2 :",
"chooseMapDialog.js561133791": "Étape 3 :",
"chooseMapDialog.js61491380": "appliquer",
"Link.js291014714": "Ouvrir hiStory",
"Link.js62298905": "Filtrer les événements dans la chronologie",
"maintenanceeventlisttable.js380608930": "Désignation",
"maintenanceeventlisttable.js866297000": "Début",
"maintenanceeventlisttable.js573824846": "Fin",
"filtertasklist.js930196063": "Tous",
"filtertasklist.js18265885": "Tous les événements",
"filtertasklist.js560877919": "Terme",
"filtertasklist.js132146692": "Période",
"filtertasklist.js8240783": "Caractéristiques",
"filtertasklist.js192609356": "Événements ponctuels",
"filtertasklist.js774361819": "Événements de période",
"filtertasklist.js700792519": "Groupes",
"filtertasklist.js482827431": "Événements individuels",
"extendedmenu.js908817077": "Impression et exportation d'images",
"extendedmenu.js336969410": "Signets",
"extendedmenu.js82030918": "Paramètres",
"extendedmenu.js7002811": "Aide",
"extendedmenu.js598943965": "Langue",
"extendedmenu.js760560118": "Accueil",
"histomaniamain.js114097156": "Afficher toute la chronologie dans la zone visible",
"import.js191606136": "Importation CSV",
"import.js112638704": "Copiez le contenu de votre fichier CSV dans le champ de texte et cliquez sur 'Importer'.",
"login.js382006560": "Abonnez-vous également à la newsletter histomania.",
"login.js416865926": "Conditions d'utilisation",
"login.js64439934": "Déclaration de confidentialité",
"login.js572661779": "J'ai lu et j'accepte les conditions d'utilisation et la déclaration de confidentialité",
"login.js230957075": "Créer un compte",
"login.js878212959": "Envoyer un nouveau mot de passe",
"editMediaDialog.js808123558": "Médias",
"markdowneditfield.js554353551": "Polices",
"markdowneditfield.js558691581": "Titre 1",
"markdowneditfield.js785963602": "Titre 2",
"markdowneditfield.js935699377": "Titre 3",
"markdowneditfield.js534085527": "Titre 4",
"markdowneditfield.js518723970": "Titre 5",
"markdowneditfield.js452157683": "Titre 6",
"markdowneditfield.js267080676": "Italique (texte sélectionné)",
"markdowneditfield.js267174421": "Gras Italique (texte sélectionné)",
"markdownhelper.js160663836": "Élément média introuvable",
"markdownhelper.js744897963": "Filtrer",
"markdownhelper.js297030165": "Afficher l'emplacement",
"markdownhelper.js702490738": "ouvrir le site web externe",
"quizErrorDialog.js818208140": "Un titre est requis pour le quiz.",
"quizErrorDialog.js316989684": "Veuillez indiquer au moins une question.",
"quizErrorDialog.js217047946": "Question #{index+1} : Indiquez la question.",
"quizErrorDialog.js592184179": "Question #{index+1} : Indiquez au maximum quatre réponses.",
"quizErrorDialog.js91808612": "Question #{index+1} : Marquez au moins une réponse correcte à l'aide des cases à cocher.",
"quizErrorDialog.js770785722": "Question #{index+1} : Indiquez au moins une réponse.",
"quizErrorDialog.js121149031": "Question #{index+1}, Réponse #{answerIndex+1} : Une réponse vide n'est pas autorisée.",
"lazygooglemap.js821091857": "Pour des raisons de protection des données, aucun contenu de Google Maps ne peut être affiché dans ce sous-domaine.",
"media.js334165907": "Erreur dans la description de la carte",
"media.js745432641": "Erreur dans la description de la carte",
"QuizEditorTitlePage.js567873608": "Page d'accueil",
"QuizEditorTitlePage.js3575109": "Description",
"QuizQuestion.js522602069": "Formulez votre question ici",
"QuizQuestion.js277305215": "Ajoutez des réponses et cochez les bonnes réponses",
"QuizQuestion.js719954254": "Une explication de la réponse peut être ajoutée ici",
"QuizQuestion.js631196400": "Le nombre de points attribué pour une bonne réponse",
"streetview.js903524730": "Pour des raisons de protection des données, aucun contenu de Google Maps ne peut être affiché dans ce sous-domaine.",
"youtube.js541386012": "Pour des raisons de protection des données, aucun contenu de YouTube ne peut être affiché dans ce sous-domaine.",
"circularmenu.js381995722": "Rechercher",

 "circularmenu.js524039849": "Mesurer les intervalles de temps dans la chronologie",
"circularmenu.js739065959": "Paramètres personnalisés",
"circularmenu.js357509198": "Signets",
"circularmenu.js636940232": "Déplier/replier tous les groupes",
"circularmenu.js355370525": "Créer une nouvelle hiStory",
"circularmenu.js753068960": "Créer un événement ponctuel",
"circularmenu.js653781889": "Créer un événement de période",
"menu.js826958696": "Déclaration de confidentialité",
"menu.js586813367": "Conditions générales et conditions d'utilisation",
"menu.js614038001": "Mentions légales",
"menu.js439856996": "Licences d'image",
"menu.js942699906": "Réinitialiser les paramètres des cookies",
"printdialog.js317202399": "Résolution",
"printdialog.js176250960": "Plage temporelle",
"printdialog.js161420731": "Paramètres de l'image",
"printdialog.js399246718": "Partager l'image",
"printdialog.js150161449": "Aperçu",
"printdialog.js8964786": "Imprimer",
"printdialog.js544885797": "Exportation d'image",
"ratings.js661212453": "Pour évaluer, tu dois te connecter.",
"displaystorydetails.js121722705": "Afficher les licences d'image",
"bookingrightsadministration.js131215424": "Ces utilisateurs sont autorisés à éditer la hiStory :",
"dummyuserhint.js706662830": () => <>Sans inscription, tu peux créer une hiStory uniquement à des fins de <b>test</b>, mais elle sera supprimée après un certain temps.</>,
"newstoryhint.js223326167": () => <>Ici, tu racontes ta hiStory, qui constitue le cadre de ta chronologie.<br/>Pour l'instant, il te suffit de donner un titre à ta hiStory et de l'enregistrer.<br/>Ensuite, tu pourras commencer à entrer les événements individuellement.</>,
"bookingrights.js286057285": "La hiStory peut être éditée par ",
"distributerightsdialog.js87761818": "Distribuer les droits",
"barexpansion.js878339034": "Hauteur",
"barexpansion.js887101724": "fois",
"barexpansion.js728758094": "Définis la hauteur affichée de l'événement comme un multiple de la hauteur normale.",
"bargroup.js152857522": "Groupe",
"bargroup.js914347576": "Modifier l'intitulé",
"barposition.js84416775": "Position",
"barposition.js560624624": "Détermine grâce à la position quels événements doivent être affichés plus bas et quels événements doivent être affichés plus haut.",
"barsettings.js434654385": "Couleur",
"barsettings.js408327828": "Choisissez la couleur de l'événement. La couleur du texte sera sélectionnée automatiquement.",
"barshape.js315964835": "Forme",
"barshape.js230080019": "Choisissez la forme de la barre de l'événement.",
"dataset.js205796920": "Données de l'histogramme",
"dataset.js666145317": "Décris les graphiques dépendants du temps.",
"dataset.js354018274": "Insère ici le contenu CSV et appuie sur OK :",
"dataset.js218289977": "Importer",
"editbookingdetails.js985514551": "Voulez-vous vraiment supprimer cet événement ?",
"imagechooser.js862610110": "Image à l'événement dans la chronologie",
"imagechooser.js871519548": "Définis une image qui apparaîtra dans la chronologie.",
"imagechooser.js950070103": "Tu peux ajouter d'autres images dans la description.",
"wikipediareference.js272862577": "Référence Wikipédia",
"wikipediareference.js904830442": "Référence à Wikipédia.",
"bookmarks.js523603774": "Voulez-vous vraiment supprimer le signet '{deleteBookmarkS && deleteBookmarkS.name}' ?",
"chooseMapDialog.js720327007": "Indique l'intitulé.",
"chooseMapDialog.js760709110": "Clique ensuite sur la carte pour placer un nouveau marqueur, ou entrez l'URL de Google Maps.",
"chooseMapDialog.js388797867": "Indique la description de l'image et la licence si votre image n'est pas libre de droits.",
"chooseMapDialog.js842057153": "Ajuste la taille sur la carte. Ajoute des points de contrôle (au moins trois) avec [Ctrl + Click].",
"chooseMapDialog.js982829124": "Choisissez ensuite ",
"Link.js776621301": "Événement dans la chronologie",
"Link.js501324063": "Ouvrir une autre hiStory.",
"import.js93314536": "Importation de JSON",
"import.js254441848": "Importation depuis le champ de texte",

  "login.js15485281": "Veuillez entrer votre adresse e-mail enregistrée chez histomania :",
  "markdowneditfield.js426937653": "Gras (texte sélectionné)",
  "markdowneditfield.js85888028": "Insérer une image",
  "markdowneditfield.js249402737": "Insérer une vidéo",
  "markdowneditfield.js421012641": "Insérer une carte",
  "markdowneditfield.js535534111": "Insérer une vue panoramique",
  "markdowneditfield.js58232052": "Insérer un filtre",
  "markdowneditfield.js954635745": "Insérer un quiz",
  "markdowneditfield.js827493202": "Liste de tous les médias",
  "markdownhelper.js904359583": "Filtrer les événements par chronologie et ligne de temps.",
  "markdownhelper.js133555219": "Trouver un événement dans la ligne de temps",
  "markdownhelper.js499018470": "Une flèche indique la position de l'événement sur la timeline.",
  "markdownhelper.js743956038": "La position de l'événement est affichée sur une carte.",
  "markdownhelper.js813604085": "Un nouvel onglet du navigateur s'ouvre avec l'article.",
  "addstorydescriptor.js556883219": "hiStories intégrées",
  "addstorydescriptor.js110462036": "nouvelle hiStory intégrée",
  "bookingrights.js50879320": "Connectez-vous pour partager des hiStories avec d'autres.",
  "admincomponent.js149123880": "Fermer",
  "loginbutton.js370395105": "se connecter / s'inscrire gratuitement",
  "mediaitemlistbutton.js846631503": "Liste de tous les médias",
  "registerbutton.js180629076": "s'inscrire gratuitement",
  "hintdialog.js158836303": "Fermer",
  "barexpansion.js805637512": "normal",
  "barexpansion.js271938727": "25 fois",
  "barexpansion.js680468558": "50 fois",
  "barexpansion.js321153821": "75 fois",
  "barexpansion.js970143224": "100 fois",
  "barposition.js477225547": "tout en bas",
  "barposition.js21166688": "normal",
  "barposition.js400652493": "tout en haut",


  "bookingrightsadministration.js501993017": "Autoriser un autre utilisateur",
  "streetview.js459438593": "J'accepte que des contenus de Google Maps me soient affichés. Cela peut entraîner la transmission de données personnelles à des tiers.",
  "displaystorydetails.js463453345": "Événements. Cliquez sur une entrée pour les détails ou sur l'icône de l'horloge pour la trouver dans la chronologie",
  "dataset.newLine": "nouvelle ligne",
  "dataset.fromCSV": "depuis csv",
  "markdowneditfield.name": "Nom",
  "markdowneditfield.birth": "Date de naissance",
  "addmarkdownbutton.53645": "Ceci est un titre Markdown",
  "timeblock.3457335": "Indiquez soit la durée soit la fin, l'autre valeur sera calculée. Sans indication, la fin se prolongera jusqu'au temps actuel.",
  "innerevents.34353463": "Actuellement, seul l'éditeur JSON est disponible pour la saisie.",
  "bargroup.54345345": "Les événements du même groupe sont affichés dans un bloc étiqueté sur la chronologie.<br/>Les noms de groupe sont sensibles à la casse, c'est-à-dire qu'ils distinguent les majuscules des minuscules.",
  "cookieconsent.0": "Avant de continuer",
  "cookieconsent.1": "Cookies techniquement nécessaires",
  "cookieconsent.2": () => <>Les cookies techniques ne peuvent pas être désactivés car sinon notre site ne pourrait plus fonctionner.<br/>Nous utilisons par exemple des cookies de session pour enregistrer la sélection actuelle de vos hiStories.<br/>De plus, des pages de YouTube et de Google Maps peuvent être intégrées dans des contenus générés par les utilisateurs.<br/>Cela peut également entraîner la mise en place de cookies par les fabricants mentionnés.<br/>Nous vous interrogeons à nouveau avant le chargement de ces contenus, pour savoir si vous le souhaitez.<br/>Votre choix sera mémorisé dans le stockage local du navigateur.<br/><br/></>,
  "cookieconsent.3": "Cookies d'analyse",
  "cookieconsent.4": "Nous utilisons Google Analytics pour créer des statistiques. Aucune donnée personnelle n'est traitée. Les adresses IP sont stockées de manière anonymisée (les derniers chiffres sont remplacés par un X).",
  "cookieconsent.5": "Vous pouvez révoquer à tout moment les paramètres de cookies et de stockage local, en sélectionnant 'Réinitialiser les paramètres des cookies' dans le menu.",
  "enlargeToSelection": "agrandir à la sélection",
  "fixDuration": "fixer la durée",
  "endMeasurement": "terminer la mesure",
  "secondsFromNow": "secondes à partir de maintenant",
  "minutesFromNow": "minutes à partir de maintenant",
  "hoursFromNow": "heures à partir de maintenant",
  "daysFromNow": "jours à partir de maintenant",
  "chronology": "chronologie",
  "languageinput.js593099979": "Langue",
  "fontSettings.js878339034": "Police",
  "fontSettings.js887101724": "-fois",
  "fontSettings.js728758094": "Définissez la hauteur de l'étiquette comme un multiple de la hauteur normale.",
  "fontSettings.quarter": "1/4",
  "fontSettings.half": "1/2",
  "fontSettings.threequarters": "3/4",
  "fontSettings.full": "normal",
  "fontSettings.oneandahalf": "1,5 fois",
  "fontSettings.two": "double",
  "fontSettings.transparency": "Opacité",
  "fontSettings.height": "Hauteur",
  "fontSettings.25percent": "25%",
  "fontSettings.50percent": "50%",
  "fontSettings.75percent": "75%",
  "fontSettings.100percent": "100%",
  "fontSettings.bold": "gras",
  "fontSettings.italic": "italique",
  "deleteProfile": "Supprimer mon profil",
  "deleteProfileContent": () => <>La suppression de votre profil ne supprime pas vos hiStories.<br/>Celles-ci restent éventuellement visibles pour d'autres utilisateurs sous un profil anonymisé.<br/>Si vous souhaitez que vos hiStories ne soient plus accessibles aux autres, supprimez-les avant de supprimer votre profil.<br/><br/>Souhaitez-vous supprimer définitivement votre profil ?</>,
  "decorationdescriptor.js556883219": "Décoration",
  "decorationdescriptor.js92624992": "Décoration",
  "menu.mapForTimeInterval": "Carte récapitulative à partir de l'intervalle de temps",
  "relation.js272862577": "Lignes de connexion",
  "relation.js904830442": "Lignes de connexion",
  "connection.target": "Cible de la liaison",
  "connection.name": "Dénomination",
  "connection.color": "Couleur",
  "connection.width": "Largeur",
  "connection.textpos": "Position du texte",
  "connection.startpos": "Position de début",
  "connection.endpos": "Position de fin",
  "schoolclass6": "6",
  "schoolclass7": "7",
  "schoolclass8": "8",
  "schoolclass9": "9",
  "schoolclass10": "10",
  "schoolclass11": "+",

  "curriculum.title": "hiStories par classes scolaires",
  "curriculum.text": "Ici, vous trouverez des hiStories sélectionnées classées par niveaux scolaires. Chaque région ayant son propre programme, le classement reste approximatif.",
  "welcome.about": "À propos de histomania",
  "pricingbutton.text": "Tarifs",
  "pricing.free": "Gratuit",
  "pricing.school": "École",
  "pricing.plus": "Plus",
  "pricing.freebody": () => <><h2 style={{display: 'inline-block'}}>0,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>00</span> €</h2><br/>version de base gratuite pour un usage privé, commercial ou éducatif.<br/>Idéal pour tester l'application.<br/><br/><hr/><br/><ul><li>gratuit en permanence</li><li>Intégration des chronologies sur des sites Web</li><li>Création de vos propres hiStories</li></ul></>,
  "pricing.schoolbody": () => <span>à partir de <h2 style={{display: 'inline-block'}}>2,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>40</span> €</h2> / élève / an<br/>Pour les inscriptions par VIDIS, edulog, avec des adresses e-mail scolaires ou configuré individuellement.<br/><br/>à partir d'un enseignant et 10 élèves/étudiants<hr/><br/><ul><li>Pleine visibilité des contenus payants</li><li>Création de contenus privés</li><li>Limité à école.histomania.com</li><li>vérifié</li></ul></span>,
  "pricing.plusbody": () => <span>à partir de <h2 style={{display: 'inline-block'}}>29,<span style={{verticalAlign: 'super', fontSize: '0.75em'}}>90</span> €</h2> / utilisateur / an<br/>Pour les utilisateurs privés et professionnels.<br/><br/><hr/><br/><ul><li>Pleine visibilité des contenus payants</li><li>Création de contenus privés</li><li>Possibilité d'exporter</li></ul></span>,
  "pricing.configureAndOrder": "Configurer et commander",
  "licenses": "Licences",
  "licenseCard.determineUsers": "Ici, vous pouvez déterminer quels utilisateurs peuvent utiliser votre licence lors de l'inscription.",
  "licenseCard.recognizeUsers": "Par quels critères les utilisateurs peuvent-ils être reconnus pour s'inscrire avec cette licence?",
  "licenseCard.schoolVidisEduca": "École (seulement VIDIS ou EDUCA)",
  "licenseCard.stateVidisEduca": "État (seulement VIDIS ou EDUCA)",
  "licenseCard.mailDomain": "Domaine e-mail",
  "licenseCard.username": "Nom d'utilisateur",
  "licenseCard.schoolLicense": "Licence scolaire",
  "licenseCard.plusLicense": "Licence Plus",
  "licenseCard.purpose": "Utilisation",
  "licenseCard.orderDate": "Commande du",
  "licenseCard.validity": "Validité",
  "licenseCard.loginType": "Connexions enseignants",
  "licenseCard.userLoginType": "Connexions utilisateur",
  "licenseCard.studentLogins": "Connexions élèves/étudiants",
  "licenseCard.status": "Statut",
  "licenseCard.paid": "payé",
  "licenseCard.paymentPending": "paiement en attente",
  "licenseCard.billingAddress": "Adresse de facturation",
  "licenseCard.showAddress": "Afficher l'adresse de facturation",
  "menu.onFacebook": "sur Facebook",
  "menu.onTwitter": "sur Twitter/X",
  "menu.onWhatsApp": "sur WhatsApp",
  "menu.embedStory": "Intégrer une hiStory",
  "menu.qrCode": "QR Code",
  "listMenu.nestedListFacebook": "Une chronologie sur",
  "listMenu.nestedListTwitter": "Une chronologie sur",
  "listMenu.nestedListWhatsApp": "Une chronologie sur",
  "listMenu.nestedListMailSubject": "Chronologie sur",
  "embed.title": "Intégrer",
  "embed.showHeader": "Afficher l'en-tête",
  "embed.showArticle": "Afficher l'article",
  "embed.darkBackground": "Arrière-plan de la chronologie sombre",
  "embed.showBgImage": "Afficher l'image en arrière-plan de la chronologie",
  "embed.showFab": "Bouton d'action flottant visible",
  "embed.htmlSnippet": "Extrait HTML",
  "sourceReference.uneImgAlt": "Une illustration de ",
  "import.title": "Importer",
  "import.warnTitle": "Avertissement",
  "import.csvTextLabel": "Texte CSV",
  "import.description": ()=><>Les lignes qui n'ont pas pu être importées restent dans le champ texte après l'importation<br/>Une description du format requis est disponible</>,
  "import.here": "ici",
  "qrCodePage.title": "QR Code",
"qrCodePage.instruction": "Vous pouvez sauvegarder et imprimer ce code QR.",
"qrCodePage.downloadButton": "Télécharger au format svg",
"histomaniamain.sessionover": "Votre session a expiré.",
"histomaniamain.checkconnection": "Veuillez vérifier la connexion Internet.",
"histomaniamain.isLoading": "chargement...",
"histomaniamain.pleasewait": "veuillez patienter",
"histomaniamain.whatif": "et si...",
"histomaniamain.pressHere": "Appuyez ici pendant 2 secondes pour créer un nouvel événement",
"histomaniamain.chooseStoryForNewEvent": "Choisissez l'histoire pour le nouvel événement.",
"export.title": "Exportation",
"export.lockWarningText": "La fonctionnalité d'exportation n'est pas disponible. En savoir plus sur l'accès complet",
"pricing.subdomain": "Vous avez besoin d'un sous-domaine personnalisé sur histomania.com ou rencontrez des problèmes de financement à votre école?",
"pricing.testAgreement": "Vous souhaitez un accord de test personnalisé?",
"pricing.contactUs": "Quelle que soit votre demande individuelle, écrivez-nous",
"pricing.vatIncluded": "Nos prix incluent la TVA.",
"pricing.findSolution": "Nous trouverons une solution ensemble.",
"priceLegendSchool.pricesPerYearPerUser": "Prix par an et par utilisateur",
"priceLegendSchool.teachers": "Enseignants",
"priceLegendSchool.from5Teachers": "à partir de 5 enseignants",
"priceLegendSchool.from10Teachers": "à partir de 10 enseignants",
"priceLegendSchool.students": "Étudiants",
"priceLegendSchool.from100Students": "à partir de 100 étudiants",
"priceLegendSchool.from300Students": "à partir de 300 étudiants",
"priceLegendPlus.pricesPerYearPerUser": "Prix par an et par utilisateur",
"priceLegendPlus.oneToFourUsers": "1-4 utilisateurs",
"priceLegendPlus.fiveToTwentyUsers": "5-20 utilisateurs",
"priceLegendPlus.twentyOneToNinetyNineUsers": "21-99 utilisateurs",
"priceLegendPlus.hundredOrMoreUsers": "100 utilisateurs et plus",
"priceCalculator.errorOccurred": "Une erreur s'est produite lors de l'envoi des données.",
"priceCalculator.contactSupport": "Veuillez contacter directement par e-mail à bestellung@histomania.com.",
"priceCalculator.licenseConfig": "Configuration de licence pour",
"priceCalculator.schools": "Écoles et organismes",
"priceCalculator.privateUsers": "utilisateurs privés et professionnels",
"priceCalculator.howManyUsers": "Combien d'utilisateurs différents travailleront avec histomania par an?",
"priceCalculator.numTeachers": "Nombre d'enseignants",
"priceCalculator.numUsers": "Nombre d'utilisateurs",
"priceCalculator.atLeastOne": "Il y aura au moins un",
"priceCalculator.calculated": "calculé.",
"priceCalculator.numStudents": "Nombre d'étudiants",
"priceCalculator.atLeastTenStudents": "Au moins 10 étudiants seront calculés.",
"priceCalculator.validFrom": "Valable à partir de",
"priceCalculator.totalPrice": "Prix total :",
"priceCalculator.perYear": "par an",
"priceCalculator.billingAddress": "Adresse de facturation",
"priceCalculator.purchaseInfo": "Pour passer une commande, vous avez besoin d'un compte histomania et devez être connecté.",
"priceCalculator.profileManagement": "Après la commande, vous pouvez voir le statut de votre licence et configurer les accès sous 'Gestion du profil | Licences'.",
"priceCalculator.defineUsers": "Vous définissez également les utilisateurs individuels ou les groupes d'utilisateurs qui pourront se connecter avec cette licence.",
"priceCalculator.includesTax": "Nos prix incluent 19% de TVA allemande.",
"priceCalculator.foreignOrders": "Pour les commandes internationales, des taxes peuvent être appliquées dans le pays de livraison, et leur paiement est à la charge de l'acheteur.",
"priceCalculator.chooseFutureDate": "Veuillez choisir une date valable qui est aujourd'hui ou dans le futur.",
"orderError.orderErrorOccurred": "Une erreur s'est produite lors de votre commande.",
"orderError.contactSupport": "Veuillez nous contacter directement à info@histomania.com.",
"checkout.pleasePayAmount": "Veuillez payer le montant de",
"checkout.within14Days": "dans les 14 jours sur le compte suivant :",
"checkout.peterRankSoftware": "Peter Rank Software",
"checkout.accountNumber": "Numéro de compte : DE55 7019 0000 0007 5237 42",
"checkout.bankCode": "Code bancaire : GENO DEF1 M01 (Banque de Munich e.G.)",
"checkout.paymentReference": "Référence de paiement :",
"checkout.payConveniently": "Ou payez rapidement et facilement par carte de crédit, Klarna, Bancontact ou virement eps :",
"checkout.continueToStripe": "Continuer vers le fournisseur de paiement Stripe",
"checkout.specifyReference": "Veuillez impérativement indiquer la référence du paiement",
"checkout.findNumberProfile": "Vous trouverez ce numéro également sous le menu 'Gestion du profil | Licences' et sur votre facture.",
"checkout.configureLicenses": "Vous pouvez également configurer vos licences là-bas.",
"checkout.toLicenseAdmin": "Vers la gestion des licences",
"checkout.noNeedToWait": "Vous n'avez pas besoin d'attendre que votre paiement soit enregistré.",
"checkout.fullAccess": "Nous avons déjà activé votre accès complet pour les 14 prochains jours.",
"checkout.reviewOrder": "Nous vérifierons votre commande et vous enverrons une facture avec les informations de paiement par e-mail à l'adresse fournie.",
"checkout.noNeedToWaitInvoice": "Vous n'avez pas besoin d'attendre de recevoir la facture et que le paiement soit effectué.",
"checkout.toLicenseAdminLower": "vers la gestion des licences",
"addressForm.schoolEmptyError": "L'école/l'organisme ne doit pas être vide.",
"addressForm.prenameEmptyError": "Le prénom ne doit pas être vide.",
"addressForm.surnameEmptyError": "Le nom de famille ne doit pas être vide.",
"addressForm.streetEmptyError": "La rue ne doit pas être vide.",
"addressForm.streetNumberEmptyError": "Le numéro de rue ne doit pas être vide.",
"addressForm.zipCodeInvalidError": "Le code postal doit comporter 5 chiffres.",
"addressForm.townEmptyError": "La ville ne doit pas être vide.",
"addressForm.countryEmptyError": "Le pays ne doit pas être vide.",
"addressForm.emailInvalidError": "Adresse e-mail invalide.",

"addressForm.countryLabel": "Pays",
"addressForm.germany": "Allemagne",
"addressForm.austria": "Autriche",
"addressForm.switzerland": "Suisse",
"addressForm.taxNumberLabel": "Numéro de taxe",
"addressForm.taxNumberHelper": "Si votre organisation possède un numéro de taxe",
"addressForm.schoolOrOrgLabel": "École/Organisation",
"addressForm.companyLabel": "Entreprise",
"addressForm.prenameLabel": "Prénom",
"addressForm.surnameLabel": "Nom",
"addressForm.streetLabel": "Rue",
"addressForm.streetNumberLabel": "Numéro de maison",
"addressForm.zipCodeLabel": "Code postal",
"addressForm.townLabel": "Ville",
"addressForm.emailLabel": "Adresse e-mail",
"zoomableImage.bild": "Image : ",
"editButton.bearbeiten": "Modifier",
"pregnancyDialog.yourPregnancyCalendar": "Votre calendrier de grossesse",
"pregnancyDialog.enterDate": "Entrez la date de vos dernières règles ou la date de la fécondation pour voir votre calendrier de grossesse personnalisé.",
"pregnancyDialog.firstDayLastPeriod": "Premier jour des dernières règles",
"pregnancyDialog.dayOfFertilization": "Jour de la fécondation",
"pregnancyDialog.from": "de",
"pregnancyDialog.loading": "Chargement...",
"pregnancyDialog.whatIf": "et si...",
"pregnancyDialog.firstDayLastPeriodTask": "Premier jour des dernières règles",
"despotbirthday.title": "Comparez-vous aux despotes",
"despotbirthday.prompt": "Et si les despotes du 20ème siècle étaient nés le même jour que vous ?",
"despotbirthday.chooseBirthday": "Indiquez votre date de naissance et vous pourrez voir ce que d'autres tyrans avaient déjà accompli à votre âge.",
"despotbirthday.label": "Quelle est votre date de naissance ?",
"despotbirthday.loading": "Chargement...",
"usersPerDay.newUsers": "Nouveaux utilisateurs par jour",
"usersPerDay.day": "Jour",
"usersPerDay.count": "Nombre",
"users.loadingError": "Erreur lors du chargement des détails des utilisateurs",
"users.privilegesError": "Erreur lors de la définition des privilèges administratifs",
"users.countText": "Utilisateurs, dont",
"users.validatedText": "validé",
"users.newsletterSubscribersText": "Abonnés à la newsletter, dont",
"users.headingUsers": "Utilisateurs",
"users.emailHeading": "Email",
"users.forenameHeading": "Prénom",
"users.surnameHeading": "Nom",
"users.newsletterHeading": "Newsletter",
"users.validatedHeading": "Validé",
"users.numHistories": "Nombre d'histoires",
"userDetails.hiStories": "hiStories",
"userDetails.id": "ID",
"userDetails.name": "Nom",
"userDetails.public": "public",
"userDetails.adminPrivileged": "Administrateur autorisé",
"userDetails.events": "Événements",
"userDetails.lastEventChange": "Dernier changement d'événement",
"userDetails.lastStoryChange": "Dernier changement d'histoire",
"topVisited.topPages": "Les pages les plus visitées",
"topVisited.visits": "Visites",
"topVisited.page": "Page",
"topVisited.creator": "Créateur",
"topVisited.events": "Événements",
"topVisited.creationTime": "Heure de création",
"topTaskCnt.title": "Pages avec de nombreux événements",
"topTaskCnt.visits": "Visites",
"topTaskCnt.page": "Page",
"topTaskCnt.creator": "Créateur",
"topTaskCnt.events": "Événements",
"topTaskCnt.creationTime": "Heure de création",
"search.from": "de",
"search.to": "à",

"titlelist.ago": (years)=>"il y a "+years+" ans",
"deleteDialog.title": "Vraiment supprimer ?",
"deleteDialog.confirmationText": "Veux-tu vraiment supprimer cette hiStory ?",
"dummyUserHint.warningText": "Créée à des fins de test, mais sera supprimée après un certain temps.",
"dummyUserHint.notPublished": "Tu ne peux pas non plus publier cette hiStory.",
"dummyUserHint.notAddedToAccount": "La hiStory ne sera pas ajoutée à ton compte si tu te connectes.",
"dummyUserHint.logonHere": "Connecte-toi",
"dummyUserHint.here": "ici",
"bookingRightsAdmin.notAvailable": "La gestion des droits n'est pas disponible.",
"bookingRightsAdmin.publiclyAccessible": "La nouvelle hiStory est accessible au public.",
"bookingRightsAdmin.learnMore": "En savoir plus sur l'attribution des droits.",
"userrightsdialog.userneverloggedin": "L'utilisateur ne s'est jamais connecté",
"displayStoryDetails.publishPrompt": "Publie cette hiStory pour qu'elle soit trouvée et vue par tout le monde :",
"displayStoryDetails.publishNow": "Publier maintenant",
"displayStoryDetails.storyProtected": "Cette hiStory est protégée.",
"displayStoryDetails.textScrambled": "Les lettres des textes ont été mélangées.",
"displayStoryDetails.learnMoreFullAccess": "En savoir plus sur l'accès complet.",
"displayStoryDetails.map": "Carte",
"displayStoryDetails.mindmap": "Carte mentale",
"displayStoryDetails.position": "Position",
"printdialog.barSizeLabel": "Hauteur de la barre [px]",
"printdialog.widthLabel": "Largeur [px]",
"printdialog.heightLabel": "Hauteur [px]",
"printdialog.altText": "Aperçu",
"printdialog.from": "de",
"printdialog.to": "à",
"print.legendSource": "Source",
"menu.active": "actif",
"youtube.consentLabel": "J'accepte que le contenu de YouTube me soit affiché. Cela peut entraîner la transmission de données personnelles à des tiers.",
"mediaFile.mapTooltip": "Afficher la carte",
"mediaFile.videoTooltip": "Afficher la vidéo",
"mediaFile.streetViewTooltip": "Afficher StreetView",
"mediaFile.quizTooltip": "Afficher le quiz",
"googleMapContent.undurchsichtig": "opaque",
"googleMapContent.durchsichtig": "transparent",
"googleMapContent.transparenz": "Transparence",
"googleMapContent.gitter": "Grille",
"googleMapContent.kontrollpunkte": "Points de contrôle",
"googleMapContent.overlay": "Superposition",
"quizQuestion.question": "Question",
"quizQuestion.addAnswer": "Ajouter une réponse",
"quizQuestion.addExplanation": "Clique ici pour insérer une explication",
"quizEditor.addQuestion": "Ajouter une question",
"quizAnswer.antwort": "Réponse ",

  "quiz.landingHeaderText": (nrOfQuestions) => nrOfQuestions + " questions",
  "quiz.question": "Question",
  "quiz.startQuizBtn": "Commencer le quiz",
  "quiz.resultFilterAll": "Tous",
  "quiz.resultFilterCorrect": "correct",
  "quiz.resultFilterIncorrect": "incorrect",
  "quiz.nextQuestionBtn": "Suivant",
  "quiz.resultPageHeaderText": (correctlength, questionslength) => "Tu as répondu correctement à " + correctlength + " sur " + questionslength + " questions.",
  "quiz.resultPagePoint": (correctPoints, totalPoints) => "Tu obtiens " + correctPoints + " sur " + totalPoints + " points.",
  "quiz.singleSelectionTagText": "Sélection unique",
  "quiz.multipleSelectionTagText": "Sélection multiple",
  "quiz.pickNumberOfSelection": (numberOfSelection) => "Choisis " + numberOfSelection + " réponses",
  "quiz.pickNumberOfSelectionSingle": "Choisis une réponse",
  "quiz.auswertung": "Évaluation",
  "instantFeedback.correctMessage": "Correct ! Veuillez cliquer sur Suivant pour continuer.",
  "instantFeedback.incorrectMessage": "Réponse incorrecte. Veuillez réessayer.",
  "markdowneditfield.videoEdit": "Modifier la vidéo",
  "markdowneditfield.mapEdit": "Modifier la carte",
  "markdowneditfield.insertTable": "Insérer un tableau",
  "markdowneditfield.hr": "Insérer une ligne horizontale",
  "markdowneditfield.linkWith": "Lier le texte sélectionné avec une histoire, un événement ou un filtre",
  "markdowneditfield.convertToBullet": "Convertir le texte sélectionné en liste à puces",
  "markdowneditfield.convertToQuote": "Convertir le texte sélectionné en citation",
  "markdowneditfield.preview": "Aperçu",
  "markdowneditfield.mdFormatting": "(Formatage markdown possible)",
  "textField.ariaSearch": "Recherche",
  "filterTimeline.tip": "Conseil : cliquez d'abord sur l'œil barré à 'Tout' pour filtrer tous les événements et ajoutez-les progressivement.",
  "filterTimeline.showIds": "Afficher les IDs non filtrés",
  "maintenanceeventlisttable.andauernd": "en cours",
  "maintenanceeventlisttable.clickToEdit": "Cliquez sur la cellule de tableau souhaitée pour éditer ou '...' pour accéder aux détails.",
  "maintenanceeventlisttable.autoSaveInfo": "Chaque modification est enregistrée automatiquement dès que vous quittez la cellule du tableau.",
  "addPanoramaButton.title": "Porte de Brandebourg",
  "imageButton.title": "Mon image",
  "chooseMarkdownDialog.markdown": "Markdown",
  "chooseMarkdownDialog.leftMargin": "marge gauche",
  "chooseMarkdownDialog.rightMargin": "marge droite",
  "chooseMapDialog.selectedPosition": "position choisie",
  "chooseMapDialog.marker": "Marqueur",
  "chooseMapDialog.arrow": "Flèche",
  "chooseMapDialog.overlay": "Superposition",
  "chooseMapDialog.newMarker": "nouveau marqueur",
  "chooseMapDialog.newArrow": "nouvelle flèche",
  "chooseMapDialog.newOverlay": "nouvelle superposition",
  "chooseMapDialog.arrowInstruction": "Cliquez le chemin de la flèche et à la fin sur ",
  "chooseMapDialog.overlayStep1": "Étape 1 :",
  "chooseMapDialog.uploadImage": "Téléchargez votre image et définissez la zone de détourage",
  "chooseMapDialog.pointEndInstruction": "Supprimez les points avec [Shift + Click].",
  "chooseInfoBlock.infoblock": "Bloc d'information",
  "chooseInfoBlock.backgroundColor": "Couleur de fond",
  "chooseInfoBlock.leftMargin": "marge gauche",
  "chooseInfoBlock.rightMargin": "marge droite",
  "chooseMapDialog.click": "Cliquez le chemin de la flèche et à la fin sur",
  "chooseMapDialog.or": "ou",
  "chooseMapDialog.takeover": "reprendre",
  "bookmarks.jsSaveBookmark": "enregistrer comme favori",
  "bookmarkItem.js.foundStories": (size) => "(Résumé de " + size + " aventures)",
  "bookmarkdialog.name": "Nom",
  "editbookingdetails.reallydelete": "Vraiment supprimer?",
  "editbookingdetails.newEvent": "Nouvel événement",
  "editbookingdetails.event": "Événement",
  "dataset.csvimport": "Importation CSV",
  "dataset.table": "Tableau",
  "dataset.json": "JSON",
  "connections.newConnection": "nouvelle connexion",
  "menu.france": "Français",
  "menu.italy": "Italien",
  "nomoretwitter": () => <>
    Jusqu'à présent, vous vous êtes connecté ici via votre compte Twitter/X.<br/>
    Demandez simplement un nouveau mot de passe via "Mot de passe oublié" à l'adresse e-mail de votre compte Twitter/X, puis connectez-vous directement chez nous.
  </>,
  "langNotAvailable": "Désolé, la langue souhaitée n'est pas disponible pour",
  "and": "et",
  "menu": "Menu",
}

export default res